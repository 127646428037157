// import axios from "axios";
// import AlertService from "./alertService";
// import { API_URL_KEY, IDENTITY_API_URL_KEY } from "../Constants/apiKeys";
// import {
// 	CURATOR_USER_TYPE,
// 	CURRENCY_KEY,
// 	DEFAULT_LANGUAGE_KEY,
// 	ERROR_KEY,
// 	LANGUAGE_KEY,
// 	REFRESH_TOKEN_KEY,
// 	TOKEN_KEY,
// 	USER_KEY,
// 	DEFAULT_CURRENCY_KEY,
// 	BACK_URL_KEY,
// 	STUDENT_USER_TYPE,
// 	LECTURER_USER_TYPE,
// } from "../Constants/mainKeys";
// import { VIDEO_PROFESSIONALISM_PATH_KEY } from "../Constants/requestKeys";
// import { post } from "jquery";

// const api = axios.create({
// 	baseURL:  process.env.REACT_APP_API_URL,
// 	withCredentials: true,
// });

// const identityApi = axios.create({
// 	baseURL: IDENTITY_API_URL_KEY,
// });

// identityApi.interceptors.request.use(async config => {
// 	config.headers = {
// 		"ngrok-skip-browser-warning": true,
// 	};
// 	return config;
// });

// api.interceptors.request.use(async config => {
// 	config.headers = {
// 		Authorization: localStorage.getItem(TOKEN_KEY)
// 			? localStorage.getItem(TOKEN_KEY)
// 			: "",
// 		language: localStorage.getItem(LANGUAGE_KEY),
// 		currency: localStorage.getItem(CURRENCY_KEY)
// 			? localStorage.getItem(CURRENCY_KEY)
// 			: DEFAULT_CURRENCY_KEY,

// 		"ngrok-skip-browser-warning": true,
// 	};
// 	return config;
// });

// const showError = errorMsg => {
// 	errorMsg && AlertService.alert(ERROR_KEY, errorMsg);
// };

// // const ServerErrorText = `
// //   <p>Sorry, something went wrong! Check your internet connection, if the problem still persists,
// //    <a
// //     id="contact-us"
// //     href=/${localStorage.getItem(LANGUAGE_KEY) ? localStorage.getItem(LANGUAGE_KEY) : DEFAULT_LANGUAGE_KEY}/contact-us
// //     class="contact-us"
// //      >contact us .</a>
// //   </p>
// // `;

// // const removeAlertBlock = () => {
// //   var currentBlock = document.getElementById("alertBlockCloseButton");
// //   if (currentBlock && currentBlock.parentNode) {
// //     currentBlock.parentNode.removeChild(currentBlock);
// //   } else { return; }
// // }

// api.interceptors.response.use(
// 	response => {
// 		// if (response && response.data && response.data?.status === 500) {
// 		//   AlertService.alert(ERROR_KEY, serverErrorText, true);
// 		//   let alertBlock = document.getElementById("alertBlockCloseButton");
// 		//   if (alertBlock) {
// 		//     let contactUs = alertBlock.querySelector("#contact-us");
// 		//     contactUs && contactUs.addEventListener("click", function () {
// 		//       removeAlertBlock();
// 		//     });
// 		//   }
// 		// }

// 		if (response && response.data && response.data?.status >= 500) {
// 			if (response.data?.message) {
// 				return Promise.reject(response.data?.message);
// 			}
// 			return Promise.reject("Sorry, something went wrong!");
// 		}

// 		if (response && response.data) {
// 			if (
// 				response.data.respmess === "User is not Authenticated" ||
// 				response.data.respmess === "This course is not belong to you!"
// 			) {
// 				window.location.href = `/${
// 					localStorage.getItem(LANGUAGE_KEY)
// 						? localStorage.getItem(LANGUAGE_KEY)
// 						: DEFAULT_LANGUAGE_KEY
// 				}`;
// 				localStorage.clear();
// 			}
// 			return !response.data.respcode
// 				? response.data
// 				: Promise.reject({
// 						respmess: response.data.respmess,
// 						respcode: response.data.respcode,
// 				  });
// 		}
// 	},
// 	error => {
// 		if (error.response && error.response.status === 401) {
// 			const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
// 			const user = localStorage.getItem(USER_KEY);
// 			const token = localStorage.getItem(TOKEN_KEY);
// 			if (!refreshToken || !user || !token) {
// 				logout();
// 			} else {
// 				getUserDataByRefreshToken(refreshToken, user);
// 			}
// 		}
// 		if (error.response) {
// 			const errors = error.response.data.errors;
// 			ApiService.getValidationTranslations()
// 				.then(response => {
// 					if (response && response.data) {
// 						for (const err in errors) {
// 							const isExistErrorText = response.data.find(
// 								error => error.shortKey === errors[err][0],
// 							);
// 							if (isExistErrorText) {
// 								showError(isExistErrorText.translation1);
// 							} else {
// 								showError(errors[err][0]);
// 							}
// 						}
// 					} else {
// 						window.location.href = `/${
// 							localStorage.getItem(LANGUAGE_KEY)
// 								? localStorage.getItem(LANGUAGE_KEY)
// 								: DEFAULT_LANGUAGE_KEY
// 						}/under-maintenance`;
// 					}
// 				})
// 				.catch(() => {
// 					window.location.href = `/${
// 						localStorage.getItem(LANGUAGE_KEY)
// 							? localStorage.getItem(LANGUAGE_KEY)
// 							: DEFAULT_LANGUAGE_KEY
// 					}/under-maintenance`;
// 				});
// 			return Promise.reject(errors || error.response.data.ExcMessage || error);
// 		} else {
// 			// Server error
// 			if (!window.location.href.includes("under-maintenance")) {
// 				localStorage.setItem(BACK_URL_KEY, window.location.pathname);
// 				window.location.href = `/${
// 					localStorage.getItem(LANGUAGE_KEY)
// 						? localStorage.getItem(LANGUAGE_KEY)
// 						: DEFAULT_LANGUAGE_KEY
// 				}/under-maintenance`;
// 			}
// 			// return Promise.reject();
// 		}
// 	},
// );

// const getUserDataByRefreshToken = (refreshToken, user) => {
// 	const formData = new FormData();
// 	formData.append(`refreshToken`, refreshToken);
// 	ApiService.getUserDataByRefreshToken(formData)
// 		.then(response => {
// 			if (response.data && response.data.accessToken) {
// 				const data = { ...response.data };
// 				localStorage.setItem(TOKEN_KEY, data?.accessToken);
// 				localStorage.setItem(REFRESH_TOKEN_KEY, data?.refreshToken);
// 				localStorage.setItem(USER_KEY, user);
// 			} else {
// 				logout();
// 			}
// 		})
// 		.catch(error => getFail(error));
// };

// const getFail = error => {
// 	if (error) {
// 		window.location.href = `/${
// 			localStorage.getItem(LANGUAGE_KEY)
// 				? localStorage.getItem(LANGUAGE_KEY)
// 				: DEFAULT_LANGUAGE_KEY
// 		}`;
// 		localStorage.clear();
// 	}
// };

// const logout = () => {
// 	window.location.href = `/${
// 		localStorage.getItem(LANGUAGE_KEY)
// 			? localStorage.getItem(LANGUAGE_KEY)
// 			: DEFAULT_LANGUAGE_KEY
// 	}`;
// 	localStorage.clear();
// };

// class ApiService {
// 	static checkServerState() {
// 		return api.get(`/Site/IsAlive`);
// 	}

// 	// RefreshToken
// 	static getUserDataByRefreshToken(data) {
// 		return identityApi.post(`/identity/renewToken`, data);
// 	}

// 	// Translations
// 	static getAllTranslations() {
// 		const data = {
// 			isocode: `${localStorage.getItem(LANGUAGE_KEY)}`,
// 			types: [0, 2, 3, 7, 8],
// 		};
// 		return api.post(`/translation/systemlangchange`, data);
// 	}
// 	static getValidationTranslations() {
// 		return api.get(
// 			`/translation?isocode=${localStorage.getItem(LANGUAGE_KEY)}&type=3`,
// 		);
// 	}
// 	static getExeptionTranslations() {
// 		return api.get(
// 			`/translation?isocode=${localStorage.getItem(LANGUAGE_KEY)}&type=4`,
// 		);
// 	}

// 	// Users
// 	static currentUser() {
// 		return api.get(`/user/current`);
// 	}
// 	static getUserById(id) {
// 		return api.get(`/user/${id}`);
// 	}
// 	static userUpdate(data) {
// 		return api.put(`/user`, data);
// 	}
// 	static changePassword(data) {
// 		return api.put(`/user/updatecredentials`, data);
// 	}
// 	static updateCurrentUserData(data) {
// 		return api.put(`/user/profile`, data);
// 	}
// 	static getResetLink(data) {
// 		return api.post(`user/changecredentialslink?email=${data}`);
// 	}
// 	static checkUserByGuid(guid) {
// 		return api.get(`/user/changecredentials/${guid}`);
// 	}
// 	static resetPassword(data) {
// 		return api.put(`/user/updatecredentials`, data);
// 	}
// 	static registration(data) {
// 		return api.post(`/user/selfregistration`, data);
// 	}
// 	static confirmEmailAndLogin(username) {
// 		return api.post(`/user/emailactivation/${username}`);
// 	}

// 	// Languages
// 	static getLanguages() {
// 		return api.get(`/language`);
// 	}
// 	static createLanguage(data) {
// 		return api.post(`/language`, data);
// 	}
// 	static updateLanguage(data) {
// 		return api.put(`/language`, data);
// 	}
// 	static deleteLanguage(id) {
// 		return api.delete(`/language/${id}`);
// 	}

// 	static getContentLanguages() {
// 		return api.get(`/library/languages`);
// 	}
// 	static getlanguageLevelsData() {
// 		return api.get(`/library/byname/language_levels`);
// 	}

// 	// Courses
// 	static getCourses() {
// 		return api.get(`/course`);
// 	}
// 	static getPublishedCourses() {
// 		return api.get(
// 			`/course/published/${
// 				localStorage.getItem("currency")
// 					? localStorage.getItem("currency")
// 					: "USD"
// 			}`,
// 		);
// 	}
// 	static getHomeCourses() {
// 		return api.get(
// 			`/course/home/${
// 				localStorage.getItem("currency")
// 					? localStorage.getItem("currency")
// 					: "USD"
// 			}`,
// 		);
// 	}
// 	static getTopCourses() {
// 		return api.get(
// 			`/course/top/${
// 				localStorage.getItem("currency")
// 					? localStorage.getItem("currency")
// 					: "USD"
// 			}`,
// 		);
// 	}
// 	static getRecommendedCourses() {
// 		return api.get(
// 			`/course/recommended/${
// 				localStorage.getItem("currency")
// 					? localStorage.getItem("currency")
// 					: "USD"
// 			}`,
// 		);
// 	}
// 	static getMyCoursesAsInstructor() {
// 		return api.get(`/course/mycoursesasinstructor`);
// 	}
// 	static getMyCoursesAsOrgUser() {
// 		return api.get(`/B2BCourse/GetLecturerCourses`);
// 	}
// 	static getMyCoursesAsStudent() {
// 		return api.get(
// 			`/course/mycoursesasstudent/${
// 				localStorage.getItem("currency")
// 					? localStorage.getItem("currency")
// 					: "USD"
// 			}`,
// 		);
// 	}
// 	static getMyCoursesAsOrgStudent() {
// 		return api.get(`/B2BCourse/GetAllMyCoursesByStudent`);
// 	}
// 	static getCourseById(id) {
// 		return api.get(`/course/${id}`);
// 	}
// 	static getCourseByIdByOrgUser(id) {
// 		return api.get(`/course/${id}`);
// 	}
// 	static getCourseByIdWithOrgUser(id) {
// 		return api.get(`/B2BCourse/GetCoursesForLecturer/${id}`);
// 	}
// 	static getBuyNowCourse(id) {
// 		return api.get(
// 			`/course/buynow/${id}/${
// 				localStorage.getItem("currency")
// 					? localStorage.getItem("currency")
// 					: "USD"
// 			}`,
// 		);
// 	}
// 	static getCourseByIdForAdmin(id) {
// 		return api.get(`/course/admin/${id}`);
// 	}
// 	static getCourseQuizzes(id) {
// 		return api.get(`/coursequiz?entitytype=0&entityId=${id}`);
// 	}
// 	static getPublishedCourseById(id) {
// 		return api.get(
// 			`/course/published/${
// 				localStorage.getItem("currency")
// 					? localStorage.getItem("currency")
// 					: "USD"
// 			}/${id}`,
// 		);
// 	}
// 	static getPublishedCourseByIdByOrgUser(id) {
// 		return api.get(`/B2BCourse/GetPublished/${id}`);
// 	}
// 	static courseDelete(id) {
// 		return api.delete(`/course/${id}`);
// 	}
// 	static courseDeleteByOrgUser(id) {
// 		return api.delete(`B2BCourse/Delete/${id}`);
// 	}
// 	static courseCreate(data) {
// 		return api.post(`/course`, data);
// 	}
// 	static createCourseByOrgUser(data) {
// 		return api.post(`/B2BCourse/Create`, data);
// 	}
// 	static updateCourseByOrgUser(data) {
// 		return api.put(`/B2BCourse/Update`, data);
// 	}
// 	static courseUpdate(data) {
// 		return api.put(`/course`, data);
// 	}
// 	static courseCostUpdate(data) {
// 		return api.put(`/course/cost`, data);
// 	}
// 	static courseTargetStudentsUpdate(data) {
// 		return api.put(`/course/targetstudents`, data);
// 	}
// 	static courseMessagesUpdate(data) {
// 		return api.put(`/course/messages`, data);
// 	}
// 	static submitForVerification(id) {
// 		return api.put(`/course/submitforverification/${id}`);
// 	}
// 	static coursePublish(id) {
// 		return api.put(`/course/publish/${id}`);
// 	}
// 	static coursePublishByOrgUser(id) {
// 		return api.put(`/studentcourse/publishcourse/${id}`);
// 	}
// 	static courseUnPublishByOrgUser(id) {
// 		return api.put(`/studentcourse/publishcourse/${id}`);
// 	}
// 	static courseFilter(data) {
// 		return api.post(
// 			`/course/filter/${
// 				localStorage.getItem("currency")
// 					? localStorage.getItem("currency")
// 					: "USD"
// 			}`,
// 			data,
// 		);
// 	}
// 	static attachStudentToCourse(data) {
// 		return api.post(`/studentcourse`, data);
// 	}
// 	static buyCoursesFromShoppingCart(data) {
// 		return api.post(`/studentcourse/shoppingcart`, data);
// 	}
// 	static getCoursePrices() {
// 		return api.get(`/library/byname/price`);
// 	}
// 	static getPricesByCurrencyId(id) {
// 		return api.get(`/librarydata/prices/${id}`);
// 	}
// 	static buyCourse(entityType, entityId) {
// 		return api.post(`/payment`, { entityType, entityId });
// 	}
// 	static getVerifiedCourses(pageNumber, pageSize) {
// 		return api.get(
// 			`/course/verified?pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 		);
// 	}
// 	static getUnverifiedCourses(pageNumber, pageSize) {
// 		return api.get(
// 			`/course/unverified?pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 		);
// 	}
// 	static getCoursesForDiscount(pageNumber, pageSize) {
// 		return api.get(
// 			`/course/discount?pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 		);
// 	}
// 	static courseDiscount(data) {
// 		return api.put(`/course/discount`, data);
// 	}
// 	static courseVerify(courseId) {
// 		return api.put(`/course/verify/${courseId}`);
// 	}
// 	static denyCourse(data) {
// 		return api.put(`/course/denyverification`, data);
// 	}

// 	// Sections
// 	static sectionDelete(id) {
// 		return api.delete(`/course/section/${id}`);
// 	}
// 	static sectionDeleteByOrgUser(id) {
// 		return api.delete(`/B2BCourse/DeleteSection/${id}`);
// 	}
// 	static sectionCreate(data) {
// 		return api.post(`/course/section`, data);
// 	}
// 	static sectionUpdate(data) {
// 		return api.put(`/course/section`, data);
// 	}
// 	static sectionCreateByOrgUser(data) {
// 		return api.post(`/B2BCourse/CreateSection`, data);
// 	}
// 	static sectionUpdateByOrgUser(data) {
// 		return api.put(`/B2BCourse/UpdateSection`, data);
// 	}
// 	static setSectionOrder(dropId, dragId) {
// 		return api.put(`/course/sectionchangeorder/${dropId}/${dragId}`);
// 	}
// 	static setSectionOrderByOrgUser(dropId, dragId) {
// 		return api.put(`/B2BCourse/ChangeSectionOrder/${dropId}/${dragId}`);
// 	}

// 	// Topics
// 	static topicDelete(id) {
// 		return api.delete(`/course/topic/${id}`);
// 	}
// 	static topicDeleteByOrgUser(id) {
// 		return api.delete(`/B2BCourse/DeleteTopic/${id}`);
// 	}
// 	static topicCreate(data) {
// 		return api.post(`/course/topic`, data);
// 	}
// 	static topicCreateByOrgUser(data) {
// 		return api.post(`B2BCourse/CreateTopic`, data);
// 	}
// 	static topicUpdate(data) {
// 		return api.put(`/course/topic`, data);
// 	}
// 	static topicUpdateByOrgUser(data) {
// 		return api.put(`/B2BCourse/UpdateTopic`, data);
// 	}
// 	static attachFileToTopic(data) {
// 		return api.post(`/courseTopicFile`, data);
// 	}
// 	static attachFileToTopicOrg(data) {
// 		return api.post(`/courseTopicFile/organization`, data);
// 	}
// 	static updateAttachedFileToTopic(data) {
// 		return api.put(`/courseTopicFile`, data);
// 	}
// 	static updateAttachedFileToTopicOrg(data) {
// 		return api.put(`/courseTopicFile/organization`, data);
// 	}
// 	static getAttachFileToTopicById(id) {
// 		return api.get(`/courseTopicFile/${id}`);
// 	}
// 	static setTopicOrder(dropId, dragId) {
// 		return api.put(`/course/topicchangeorder/${dropId}/${dragId}`);
// 	}
// 	static setTopicOrderByOrgUser(dropId, dragId) {
// 		return api.put(`/B2BCourse/ChangeTopicOrder/${dropId}/${dragId}`);
// 	}
// 	static getCurrentTopicFiles(topicId) {
// 		return api.get(`/courseTopicFile/topicid/${topicId}/false`);
// 	}
// 	static getCurrentTopicQuizzes(topicId) {
// 		return api.get(`/coursequiz?entitytype=1&entityId=${topicId}`);
// 	}

// 	// File library
// 	static getLibraryFiles(pageNumber, pageSize, fileName) {
// 		if (fileName) {
// 			return api.get(
// 				`/filelibrary/pagination?pageNumber=${pageNumber}&pageSize=${pageSize}&$filename=${
// 					fileName ? fileName : null
// 				}`,
// 			);
// 		} else {
// 			return api.get(
// 				`/filelibrary/pagination?pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 			);
// 		}
// 	}
// 	static getLibraryFileById(id) {
// 		return api.get(`/filelibrary/${id}`);
// 	}
// 	static libraryFileDelete(id) {
// 		return api.delete(`/filelibrary/${id}`);
// 	}
// 	static libraryFileCreate(data, subPath = "") {
// 		return api.post(subPath ? `/filelibrary/${subPath}` : `/filelibrary`, data);
// 	}
// 	static libraryFileCreateFromCourse(data) {
// 		return api.post(`/filelibrary/course`, data);
// 	}
// 	static libraryFileUpdate(data) {
// 		return api.put(`/filelibrary`, data);
// 	}
// 	static getFileLibrary() {
// 		return api.get(`/filelibrary/currentstudent`);
// 	}
// 	static getFileLibraryByTopicId(id, isAllFiles) {
// 		return api.get(`/courseTopicFile/topicid/${id}/${isAllFiles}`);
// 	}
// 	static getFileLibraryByTopicIdFileTypeAndCategoryId(
// 		topicId,
// 		categoryId,
// 		fileType,
// 		isAllFiles,
// 	) {
// 		return api.get(
// 			`/courseTopicFile/bytopic/${fileType}/${topicId}/${categoryId}/${isAllFiles}`,
// 		);
// 	}

// 	static getFileLibraryByFileTypeAndCategoryId(categoryId, fileType) {
// 		return api.get(`/filelibrary/${fileType}/${categoryId}`);
// 	}

// 	static getFileLibraryByCategoryId(categoryId) {
// 		return api.get(`/filelibrary/bycategory/${categoryId}`);
// 	}

// 	// Categories
// 	static getCategories() {
// 		return api.get(`/category`);
// 	}
// 	static getMainCategories(categoryType) {
// 		return api.get(`/category/mains/${categoryType}`);
// 	}
// 	static getCategoriesByType(type) {
// 		if (+type === 2) {
// 			return api.get(`/category/course`);
// 		} else {
// 			return api.get(`/category/bytype/${type}`);
// 		}
// 	}
// 	static getCategoryById(id) {
// 		return api.get(`/category?id=${id}`);
// 	}
// 	static categoryCreate(data) {
// 		return api.post(`/category/filelibfolder`, data);
// 	}
// 	static categoryUpdate(data) {
// 		return api.put(`/category/filelibfolder`, data);
// 	}
// 	static categoryDelete(id) {
// 		return api.delete(`/category/filelibfolder/${id}`);
// 	}
// 	static getThemeById(id) {
// 		return api.get(`/theme/${id}`);
// 	}
// 	static removeTheme(id) {
// 		return api.delete(`/theme/${id}`);
// 	}
// 	static createCategory(data) {
// 		return api.post(`/category`, data);
// 	}
// 	static createTheme(data) {
// 		return api.post(`/theme`, data);
// 	}
// 	static updateTheme(data) {
// 		return api.put(`/theme`, data);
// 	}
// 	static getThemesByMainCategoryId(mainCategoryId) {
// 		return api.get(`/theme/bymain/${mainCategoryId}`);
// 	}
// 	static getSubCategories(mainCategoryId) {
// 		return api.get(`/category/children/${mainCategoryId}`);
// 	}

// 	// Questions
// 	static getQuestionsByQuestionGroupId(id) {
// 		return api.get(
// 			`/question?isocode=${localStorage.getItem(
// 				"language",
// 			)}&questionsGroupId=${id}`,
// 		);
// 	}
// 	static getQuestionById(id) {
// 		return api.get(`/question/${id}`);
// 	}
// 	static questionDelete(id) {
// 		return api.delete(`/question/${id}`);
// 	}
// 	static questionCreate(data) {
// 		return api.post(`/question`, data);
// 	}
// 	static questionUpdate(data) {
// 		return api.put(`/question`, data);
// 	}

// 	// Questions groups
// 	static getQuestionsGroups() {
// 		return api.get(`/questionsGroup`);
// 	}
// 	static getQuestionsGroupById(id) {
// 		return api.get(`/questionsGroup/${id}`);
// 	}
// 	static getQuizQuestionsGroup(quizId, groupId) {
// 		return api.get(
// 			`quizquestion/byquestionsgroup?$id=${quizId}&questionsGroupId=${groupId}`,
// 		);
// 	}
// 	static questionsGroupDelete(id) {
// 		return api.delete(`/questionsGroup/${id}`);
// 	}
// 	static questionsGroupCreate(data) {
// 		return api.post(`/questionsGroup`, data);
// 	}
// 	static questionsGroupUpdate(data) {
// 		return api.put(`/questionsGroup`, data);
// 	}

// 	// Quizzes
// 	static quizCreate(data) {
// 		return api.post(`/coursequiz`, data);
// 	}
// 	static quizUpdate(data) {
// 		return api.put(`/coursequiz`, data);
// 	}
// 	static getQuizById(quizId) {
// 		return api.get(`/coursequiz/${quizId}`);
// 	}
// 	static getQuizByIdByOrgStudent(quizId) {
// 		return api.get(`/coursequiz/student/${quizId}`);
// 	}
// 	static quizDelete(quizId) {
// 		return api.delete(`/coursequiz/${quizId}`);
// 	}
// 	static getQuizzesByCourseId(id) {
// 		return api.get(`/coursequiz?entitytype=0&entityId=${id}`);
// 	}

// 	static getQuizzesByCourseIdByOrgStudent(id) {
// 		return api.get(`/coursequiz/student?entitytype=0&entityId=${id}`);
// 	}
// 	static getQuizzesByTopicId(id) {
// 		return api.get(`/coursequiz?entitytype=1&entityId=${id}`);
// 	}

// 	// Quiz question
// 	static createQuizQuestion(data) {
// 		return api.post(`/quizQuestion/bulkSave`, data);
// 	}

// 	// Quiz attempts
// 	static createQuizAttempt(id) {
// 		return api.post(`/quizattempt/${id}`);
// 	}
// 	static getOnGoingQuizAttempt(id) {
// 		return api.get(`/quizattempt/ongoing/${id}`);
// 	}
// 	static getReviewQuizAttempt(id) {
// 		return api.get(`/quizattempt/review/${id}`);
// 	}
// 	static getQuizAttempts(id) {
// 		return api.get(`/quizattempt/${id}`);
// 	}
// 	static checkQuizQuestion(data) {
// 		return api.post(`/quizattempt/question`, data);
// 	}
// 	static submitQuizQuestion(id, data) {
// 		return api.post(`/quizattempt/submit/${id}`, data);
// 	}
// 	static submitQuizQuestionLeave(id, data) {
// 		return api.post(`/quizattempt/submitquestions/${id}`, data);
// 	}
// 	static finishAttempt(id) {
// 		return api.get(`/quizattempt/finish/${id}`);
// 	}

// 	// Library
// 	static getReviewOptionsCategoryItems() {
// 		return api.get(`/library/byname/quizreviewoptions`);
// 	}
// 	static getLibraryData() {
// 		return api.get(`/library/byname/${VIDEO_PROFESSIONALISM_PATH_KEY}`);
// 	}
// 	static getCourseTicketSubjects() {
// 		return api.get(`/library/byname/course_ticket_subject`);
// 	}
// 	static getConsultationTicketSubjects() {
// 		return api.get(`/library/byname/consultation_ticket_subject`);
// 	}
// 	static getComplaintData(entityType, id) {
// 		return api.get(`/complaint/current/${entityType}/${id}`);
// 	}

// 	// Instructor
// 	static becomeInstructor(data) {
// 		return api.post(`/instructor`, data);
// 	}
// 	static getCurrentInstructorData() {
// 		return api.get(`/instructor/current`);
// 	}
// 	static getInstructorData(instructorId) {
// 		return api.get(`instructor/${instructorId}`);
// 	}
// 	static getInstructorOrConsultantData(id) {
// 		return api.get(`/user/profile/${id}`);
// 	}
// 	static getInstructorCourses(instructorId) {
// 		return api.get(`/course/instructorcourses/${instructorId}`);
// 	}
// 	static updateInstructor(data) {
// 		return api.put(`/instructor`, data);
// 	}

// 	// Join meeting
// 	static joinMeeting(data) {
// 		return api.post(`/user/tempRegistration`, data);
// 	}

// 	// Page content get
// 	static getPagecontent(id) {
// 		return api.get(`/pagecontent/${id}`);
// 	}

// 	static getHomePagecontent() {
// 		return api.get(`/page/homepage`);
// 	}

// 	// Pass-Course
// 	static getStudentCourseByStudentCourseId(studentCourseId) {
// 		return api.get(`/studentcourse/startcontinuecourse/${studentCourseId}`);
// 	}
// 	static getStudentCourseAndTopicData(studentCourseId, topicId) {
// 		return api.get(
// 			`/studentcourse/gettopicandcourse/${studentCourseId}/${topicId}`,
// 		);
// 	}
// 	static getStudentCourseAndTopicDataByOrgStudent(studentCourseId, topicId) {
// 		return api.get(
// 			`/studentcourse/organization/gettopicandcourse/${studentCourseId}/${topicId}`,
// 		);
// 	}
// 	static getTopicData(studentCourseId, topicId) {
// 		return api.get(`/studentcourse/getTopic/${studentCourseId}/${topicId}`);
// 	}
// 	static getStudentCourse(id) {
// 		return api.get(`/studentcourse/startcontinuecourse/${id}`);
// 	}

// 	//Notification
// 	static getNotifications() {
// 		return api.get(`/notification`);
// 	}
// 	static getAllNotifications(pageNumber, pageSize) {
// 		return api.get(
// 			`/notification/all?pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 		);
// 	}
// 	static markAsReadNotification(notificationIds) {
// 		return api.put(`/notification/setasread`, notificationIds);
// 	}

// 	// Video Timestump
// 	static updateVideoTimestump(studentCourseId, topicId, videoTimestamp) {
// 		return api.put(
// 			`/studentcourse/updatetimestamp?studentCourseId=${studentCourseId}&id=${topicId}&videoTimestamp=${videoTimestamp}`,
// 		);
// 	}

// 	//Currency
// 	static getCurrency() {
// 		return api.get(`/currency`);
// 	}

// 	static getCurrencyById(currencyId) {
// 		return api.get(`/currency/${currencyId}`);
// 	}

// 	static deleteCurrency(currencyId) {
// 		return api.delete(`/currency/${currencyId}`);
// 	}

// 	static createCurrency(data) {
// 		return api.post(`/currency`, data);
// 	}

// 	static upDateCurrency(data) {
// 		return api.put(`/currency`, data);
// 	}

// 	static changeCurrency(id) {
// 		return api.put(`/balance/${id}`);
// 	}

// 	//Shopping Cart and Wish list
// 	static getWishListCourses() {
// 		return api.get(
// 			`/course/wishlist/${
// 				localStorage.getItem("currency")
// 					? localStorage.getItem("currency")
// 					: "USD"
// 			}`,
// 		);
// 	}
// 	static getWishListCoursesForUnauthUsers(data) {
// 		return api.post(
// 			`/course/unauthorized/wishlist/${
// 				localStorage.getItem("currency")
// 					? localStorage.getItem("currency")
// 					: "USD"
// 			}`,
// 			data,
// 		);
// 	}
// 	static getShoppingCartCoursesForUnauthUsers(data) {
// 		return api.post(
// 			`/course/unauthorized/shoppingcart/${
// 				localStorage.getItem("currency")
// 					? localStorage.getItem("currency")
// 					: "USD"
// 			}`,
// 			data,
// 		);
// 	}
// 	static getShoppingCartCourses() {
// 		return api.get(
// 			`/course/shoppingcart/${
// 				localStorage.getItem("currency")
// 					? localStorage.getItem("currency")
// 					: "USD"
// 			}`,
// 		);
// 	}
// 	static moveCourseToWishListOrCart(courseId, isShoppingCart) {
// 		return api.put(`/wishlistorcart`, { courseId, isShoppingCart });
// 	}
// 	static addCourseToWishListOrCart(courseId, isShoppingCart, buyNow) {
// 		return api.post(`/wishlistorcart`, {
// 			courseId,
// 			isShoppingCart,
// 			buyNow,
// 		});
// 	}
// 	static wishListOrCartBulkcreate(data) {
// 		return api.post(`/wishlistorcart/bulkcreate `, data);
// 	}
// 	static removeCourseFromWishList(courseId) {
// 		return api.delete(`/wishlistorcart/wishlist/${courseId}`);
// 	}
// 	static removeCourseFromShoppingCart(courseId) {
// 		return api.delete(`/wishlistorcart/shoppingcart/${courseId}`);
// 	}
// 	static removeAllCoursesFromShoppingCart(courseIds) {
// 		return api.post(`/wishlistorcart/removefromshoppingcart`, courseIds);
// 	}
// 	static checkPrices(totalPrice, currencyId) {
// 		return api.get(
// 			`/course/checkprice?totalPrice=${totalPrice}&currencyid=${currencyId}`,
// 		);
// 	}
// 	static checkPriceBuyNow(totalPrice, courseId) {
// 		return api.get(
// 			`/course/checkpricebuynow?totalPrice=${totalPrice}&courseId${courseId}`,
// 		);
// 	}

// 	//Consultation
// 	static getConsultationHours() {
// 		return api.get(`/library/byname/consultation_hours`);
// 	}
// 	static createConsultant(data) {
// 		return api.post(`/consultant`, data);
// 	}
// 	static updateConsultant(data) {
// 		return api.put(`/consultant`, data);
// 	}
// 	static filterConsultants(data) {
// 		return api.post(
// 			`/consultant/filter/${
// 				localStorage.getItem("currency")
// 					? localStorage.getItem("currency")
// 					: "USD"
// 			}`,
// 			data,
// 		);
// 	}
// 	static createConsultation(data) {
// 		return api.post(`/consultation`, data);
// 	}
// 	static updateConsultation(data) {
// 		return api.put(`/consultation`, data);
// 	}
// 	static getPotentialConsultations(pageNumber, pageSize) {
// 		return api.get(
// 			`/consultation/potentialconsultations/${
// 				localStorage.getItem("currency")
// 					? localStorage.getItem("currency")
// 					: "USD"
// 			}/?pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 		);
// 	}
// 	static getPotentialconsultationsCount() {
// 		return api.get(
// 			`/consultation/potentialconsultationsCount/${
// 				localStorage.getItem("currency")
// 					? localStorage.getItem("currency")
// 					: "USD"
// 			}`,
// 		);
// 	}
// 	static getConsultations() {
// 		return api.get(`/consultation`);
// 	}
// 	static getConsultantData() {
// 		return api.get(`/consultant/current`);
// 	}
// 	static getAvailableHours() {
// 		return api.get(`/library/byname/available_hours`);
// 	}
// 	static getExperienceLevels() {
// 		return api.get(`/library/byname/experience_level`);
// 	}
// 	static getConsultantContracts() {
// 		return api.get(
// 			`/consultation/consultantcontracts/${
// 				localStorage.getItem(CURRENCY_KEY)
// 					? localStorage.getItem(CURRENCY_KEY)
// 					: DEFAULT_CURRENCY_KEY
// 			}`,
// 		);
// 	}
// 	static getStudentContracts() {
// 		return api.get(
// 			`/consultation/studentcontracts/${
// 				localStorage.getItem(CURRENCY_KEY)
// 					? localStorage.getItem(CURRENCY_KEY)
// 					: DEFAULT_CURRENCY_KEY
// 			}`,
// 		);
// 	}
// 	static acceptContractConsultant(consultationId, coverLetter) {
// 		return api.put(`/consultation/acceptcontractconsultant`, {
// 			consultationId,
// 			coverLetter,
// 		});
// 	}
// 	static acceptContractStudent(consultationId, consultantId) {
// 		return api.put(`/consultation/acceptcontractstudent`, {
// 			consultationId,
// 			consultantId,
// 		});
// 	}
// 	static declineContractConsultant(id) {
// 		return api.put(`/consultation/declinecontractconsultant/${id}`);
// 	}
// 	static declineContractStudent(id) {
// 		return api.put(`/consultation/declinecontractstudent/${id}`);
// 	}
// 	static applyConsultation(consultationId, coverLetter) {
// 		return api.put(`/consultation/applyforconsultation`, {
// 			consultationId,
// 			coverLetter,
// 		});
// 	}
// 	static getContractByIdForConsultant(id) {
// 		return api.get(
// 			`/consultation/contractbyconsultant/${
// 				localStorage.getItem(CURRENCY_KEY)
// 					? localStorage.getItem(CURRENCY_KEY)
// 					: DEFAULT_CURRENCY_KEY
// 			}/${id}`,
// 		);
// 	}
// 	static getContractByIdForStudent(id) {
// 		return api.get(
// 			`/consultation/contractbystudent/${
// 				localStorage.getItem(CURRENCY_KEY)
// 					? localStorage.getItem(CURRENCY_KEY)
// 					: DEFAULT_CURRENCY_KEY
// 			}/${id}`,
// 		);
// 	}
// 	static updateDateByStudent(id, date) {
// 		return api.put(`/consultation/updatedatebystudent`, {
// 			id,
// 			date,
// 		});
// 	}
// 	static updateDateByConsultant(id, date) {
// 		return api.put(`/consultation/updatedatebyconsultant`, {
// 			id,
// 			date,
// 		});
// 	}
// 	static signContract(id, offset) {
// 		return api.put(`/consultation/signcontract/${id}/${offset}`);
// 	}
// 	static getContractIdByWebinarId(id) {
// 		return api.get(`/consultation/contractid/${id}`);
// 	}
// 	static getConsultationById(consultationId) {
// 		return api.get(
// 			`/consultation/${consultationId}/${
// 				localStorage.getItem(CURRENCY_KEY)
// 					? localStorage.getItem(CURRENCY_KEY)
// 					: DEFAULT_CURRENCY_KEY
// 			}`,
// 		);
// 	}
// 	static removeConsultation(id) {
// 		return api.delete(`/consultation/${id}`);
// 	}
// 	static getConsultationSettings() {
// 		return api.get(`/consultation/settings`);
// 	}

// 	//Messages
// 	static getUnreadMessagesData() {
// 		return api.get(`/allMessages`);
// 	}
// 	static getAllMessagesData() {
// 		return api.get(`/allMessages/seeall`);
// 	}
// 	static getAllMessagesByOrgUser() {
// 		return api.get(`/message/availableusers`);
// 	}
// 	static getChat(id) {
// 		return api.get(`/message/onlybyconcretesender?senderid=${id}`);
// 	}
// 	static getTicketChatFromAdmin(chatType, ticketId, studentUserId) {
// 		return api.get(`/message/${chatType}/${ticketId}/${studentUserId}`);
// 	}
// 	static getTicketChatFromStudent(chatType, ticketId) {
// 		return api.get(`/message/${chatType}/${ticketId}`);
// 	}
// 	static getConsultationChatFromConsultant(chatType, contratId, studentUserId) {
// 		return api.get(`/message/${chatType}/${contratId}/${studentUserId}`);
// 	}
// 	static getConsultationChatFromStudent(chatType, contratId) {
// 		return api.get(`/message/${chatType}/${contratId}`);
// 	}
// 	static sendMessage(data) {
// 		return api.post(`/message`, data);
// 	}
// 	static sendMessageFromStudent(data) {
// 		return api.post(`/message/bytype`, data);
// 	}
// 	static sendMessageFromConsultantOrAdmin(data) {
// 		return api.post(`/message/bytypeadminconsultant`, data);
// 	}

// 	//Webinars
// 	static createOneTimeWebinar(data) {
// 		return api.post(`/webinar/onetime`, data);
// 	}
// 	static updateOneTimeWebinar(data) {
// 		return api.put(`/webinar/onetime`, data);
// 	}
// 	static deleteOneTimeWebinar(webinarCalendarId) {
// 		return api.delete(`/webinar/onetimewebinarCalendar/${webinarCalendarId}`);
// 	}
// 	static checkOneTimeWebinarDate(id) {
// 		return api.get(`/webinar/onetime/${id}`);
// 	}
// 	static getCurrentUserWebinars(pageNumber, pageSize) {
// 		return api.get(
// 			`/webinar/onetime?pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 		);
// 	}
// 	static joinToWebinar(webinarCalendarId, firstname) {
// 		return api.post(`/user/onetimewebinaruser`, {
// 			webinarCalendarId,
// 			firstname,
// 		});
// 	}
// 	static getWebinarsByOrgUser(pageNumber, pageSize, user) {
// 		if (user.userTypeId === STUDENT_USER_TYPE) {
// 			return api.get(
// 				`/b2BWebinar/GetByPaginationForStudent?pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 			);
// 		} else if (
// 			user.userTypeId === LECTURER_USER_TYPE ||
// 			(user.defaultUserTypeIds &&
// 				user.defaultUserTypeIds.lebgth &&
// 				user.defaultUserTypeIds.includes(LECTURER_USER_TYPE))
// 		) {
// 			return api.get(
// 				`/b2BWebinar/GetByPaginationForLecturer?pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 			);
// 		} else if (
// 			user.userTypeId === CURATOR_USER_TYPE ||
// 			(user.defaultUserTypeIds &&
// 				user.defaultUserTypeIds.lebgth &&
// 				user.defaultUserTypeIds.includes(CURATOR_USER_TYPE))
// 		) {
// 			return api.get(
// 				`/b2BWebinar/GetByPaginationForCurator?pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 			);
// 		}
// 		return api.get(
// 			`/b2BWebinar/GetByPaginationForStudent?pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 		);
// 	}
// 	static getOnGoingWebinars(pageNumber, pageSize) {
// 		return api.get(
// 			`/b2BWebinar/GetOngoingWebinars?pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 		);
// 	}
// 	static getWebinarbyIdFromOrgUser(webinarId, user) {
// 		if (user.userTypeId === STUDENT_USER_TYPE) {
// 			return api.get(`/b2bwebinar/GetByStudent/${webinarId}`);
// 		} else if (
// 			user.userTypeId === LECTURER_USER_TYPE ||
// 			(user.defaultUserTypeIds &&
// 				user.defaultUserTypeIds.lebgth &&
// 				user.defaultUserTypeIds.includes(LECTURER_USER_TYPE))
// 		) {
// 			return api.get(`/b2bwebinar/GetByLecturer/${webinarId}`);
// 		} else if (
// 			user.userTypeId === CURATOR_USER_TYPE ||
// 			(user.defaultUserTypeIds &&
// 				user.defaultUserTypeIds.lebgth &&
// 				user.defaultUserTypeIds.includes(CURATOR_USER_TYPE))
// 		) {
// 			return api.get(`/b2bwebinar/GetForCurator/${webinarId}`);
// 		}
// 		return api.get(`/b2bwebinar/GetForCurator/${webinarId}`);
// 	}
// 	static getInProcessWebinar() {
// 		return api.get(`/webinar/ongoingonetime`);
// 	}
// 	static finishWebinar(id) {
// 		return api.put(`/webinar/closeWebinar/${id}`);
// 	}

// 	//Payment
// 	static getBalance() {
// 		return api.get(
// 			`/balance/byisocode/${
// 				localStorage.getItem(CURRENCY_KEY)
// 					? localStorage.getItem(CURRENCY_KEY)
// 					: DEFAULT_CURRENCY_KEY
// 			}`,
// 		);
// 	}
// 	static getBalanceAsOrgUser() {
// 		return api.get(`/balance/organization`);
// 	}

// 	static getPaymentHistory() {
// 		return api.get(`/payment`);
// 	}
// 	static getPaymentHistoryWithPagination(
// 		paymentType,
// 		entityType = 0,
// 		pageNumber,
// 		pageSize = 10,
// 	) {
// 		return api.get(
// 			`/payment/history/${paymentType}/${entityType}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 		);
// 	}
// 	static getPaymentSystems() {
// 		return api.get(`/paymentSystem`);
// 	}
// 	static paymentFillPayPal(data) {
// 		return api.post(`/paypal`, data);
// 	}
// 	static paymentFillStripe(data) {
// 		return api.post(`/stripe`, data);
// 	}
// 	static paymentFillCryllex(data) {
// 		return api.post(`/cryllex`, data);
// 	}
// 	static sendStripeResponse(data) {
// 		return api.post(`/stripe/chekoutResponse`, data);
// 	}
// 	static paymentFill(data) {
// 		return api.post(`/payment`, data);
// 	}
// 	static paymentAndPurchasePaypalCourse(data) {
// 		return api.post(`/paypal/coursepurchase`, data);
// 	}
// 	static coursePurchase(data) {
// 		return api.post(`/payment/coursepurchase`, data);
// 	}
// 	static paymentAndPurchaseStripeCourse(data) {
// 		return api.post(`/stripe/coursepurchase`, data);
// 	}
// 	static paymentAndPurchasePaypalPackage(data) {
// 		return api.post(`/paypal/packagepurchase`, data);
// 	}
// 	static paymentAndPurchaseStripePackage(data) {
// 		return api.post(`/payment/packagepurchase`, data);
// 	}
// 	static paymentAndPurchasePackage(data) {
// 		return api.post(`/payment/packagepurchase`, data);
// 	}
// 	static paymentAndPurchaseBankTransaction(data) {
// 		return api.post(`/organizationPaymentPackage/selectpackage`, data);
// 	}
// 	static payOut(data) {
// 		return api.post(`/payment/payout`, data);
// 	}
// 	static payPalPayOut(data) {
// 		return api.post(`/paypal/payout`, data);
// 	}
// 	static stripePayOut(data) {
// 		return api.post(`/stripe/payout`, data);
// 	}
// 	static paymentPayOut(data) {
// 		return api.post(`/payment/payout`, data);
// 	}
// 	static getPayPalResponse(status, url) {
// 		return api.get(`/paypal/${status}/${url}`);
// 	}
// 	static getCryllexResponse(status, url) {
// 		return api.get(`/cryllex/${status}/${url}`);
// 	}
// 	static getStripeResponse(status, id) {
// 		return api.get(`/stripe/${status}?paymentId=${id}`);
// 	}
// 	static successAndCheckoutPayPal(data) {
// 		return api.post(`/paypal/successandpurchasecourses`, data);
// 	}
// 	static successAndCheckoutPayPalPackage(data) {
// 		return api.post(`/paypal/successandpurchasepackage`, data);
// 	}
// 	static checkStudentBalance(id) {
// 		return api.get(`/consultation/checkbalance/${id}`);
// 	}

// 	//Feedback
// 	static sendFeedBackFromStudent(data) {
// 		return api.post(`/review`, data);
// 	}
// 	static updateFeedBackFromStudent(data) {
// 		return api.put(`/review`, data);
// 	}
// 	static sendFeedBackFromConsultant(data) {
// 		return api.put(`/review/reply`, data);
// 	}
// 	static getFeedBackDataForStudent(entitytype, entityid) {
// 		return api.get(`/review/current/${entitytype}/${entityid}`);
// 	}
// 	static getFeedBackDataForConsultant(entitytype, contractId) {
// 		return api.get(`/review/currentbyConsultant/${entitytype}/${contractId}`);
// 	}
// 	static getFeedbacksByEntitytype(entitytype, pageNumber, pageSize) {
// 		return api.get(
// 			`/review/notapproved/${entitytype}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 		);
// 	}
// 	static approveFeedback(id) {
// 		return api.put(`/review/approve/${id}`);
// 	}
// 	static declineFeedback(data) {
// 		return api.put(`/review/decline`, data);
// 	}

// 	//complaint
// 	static makeAcomplaint(data) {
// 		return api.post(`/complaint`, data);
// 	}

// 	//Tickets
// 	static getTicketsData() {
// 		return api.get(`/complaint/current`);
// 	}
// 	static getInprocessTickets(pageNumber, pageSize) {
// 		return api.get(
// 			`/complaint/admin/inprocess?pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 		);
// 	}
// 	static getResolvedTickets(pageNumber, pageSize) {
// 		return api.get(
// 			`/complaint/admin/resolved?pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 		);
// 	}
// 	static getTicketByIdFromAdmin(ticketId) {
// 		return api.get(`/complaint/admin/${ticketId}`);
// 	}
// 	static getTicketByIdFromStudent(ticketId) {
// 		return api.get(`/complaint/student/${ticketId}`);
// 	}
// 	static refundTicket(ticketId) {
// 		return api.put(`/complaint/admin/refund/${ticketId}`);
// 	}
// 	static rejectTicket(ticketId) {
// 		return api.put(`/complaint/admin/declinerefund/${ticketId}`);
// 	}
// 	static resolveTicket(ticketId) {
// 		return api.put(`/complaint/admin/resolve/${ticketId}`);
// 	}

// 	//Search
// 	static globalSearch(text) {
// 		return api.get(`/search?keyword=${text}`);
// 	}

// 	//Specialty
// 	static getSpecialtyKeywords(specialty) {
// 		return api.get(`/librarydata/specialty?keyword=${specialty}`);
// 	}

// 	//key words admin
// 	static getSpecialityKeyWords(pageNumber, pageSize) {
// 		return api.get(
// 			`/librarydata/specialties?pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 		);
// 	}
// 	static getSearchKeyWords(pageNumber, pageSize) {
// 		return api.get(
// 			`/search/unconfirmed?pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 		);
// 	}
// 	static confirmSpecialityKeyWord(id) {
// 		return api.put(`/librarydata/specialty/${id}`);
// 	}
// 	static declineSpecialityKeyWord(id) {
// 		return api.delete(`/librarydata/specialty/${id}`);
// 	}
// 	static confirmSearchKeyWord(id) {
// 		return api.put(`/search/confirm/${id}`);
// 	}
// 	static declineSearchKeyWord(id) {
// 		return api.delete(`/search/${id}`);
// 	}
// 	static buldRemoveSpecialities(data) {
// 		return api.post(`/librarydata/specialty/bulkdelete`, data);
// 	}
// 	static buldRemoveKeyWords(data) {
// 		return api.post(`/search/bulkdelete`, data);
// 	}

// 	//Info pages
// 	static getMindalayForBusinessInfoPageContent() {
// 		return api.get(`/page/byname/TR_MINDALAY_FOR_BUSINESS_INFO_PAGE`);
// 	}
// 	static getLearnInMindalayInfoPageContent() {
// 		return api.get(`/page/byname/TR_LEARN_IN_MINDALAY_INFO_PAGE`);
// 	}
// 	static getTeachInMindalayInfoPageContent() {
// 		return api.get(`/page/byname/TR_TEACH_IN_MINDALAY_INFO_PAGE`);
// 	}
// 	static getBecomeConsultantInfoPageContent() {
// 		return api.get(`/page/byname/TR_BECOME_A_CONSULTANT_INFO_PAGE`);
// 	}

// 	//Organization pages
// 	static getPagesFromOrgUser() {
// 		return api.get(`/page/struct?frontendpage=false`);
// 	}
// 	static getPagesFromOrgUserByUserTypeId(userTypeId) {
// 		return api.get(
// 			`/page/struct?frontendpage=false&defaultUserTypeId=${userTypeId}`,
// 		);
// 	}
// 	static getPrograms() {
// 		return api.get(`/organizationEducationLevel/programnames`);
// 	}
// 	static getSubjectsByProgramId(programId) {
// 		// return api.get(`/${ORGANIZATION_EDUCATION_LEVEL_KEY}/${programId}`);
// 		return api.get(
// 			`/organizationEducationLevel/subjectbyprogramname/${programId}`,
// 		);
// 	}
// 	static getStudentsByLecturer(pageNumber = null, pageSize) {
// 		if (pageNumber) {
// 			return api.get(
// 				`/studentcourse/lecturer/allstudents?pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 			);
// 		}
// 		return api.get(`/studentcourse/lecturer/allstudents`);
// 	}
// 	static getStudentsByCurator(pageNumber = null, pageSize) {
// 		if (pageNumber) {
// 			return api.get(
// 				`/Division/GetAllStudentsOfCurator?pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 			);
// 		}
// 		return api.get(`/Division/GetAllStudentsOfCurator`);
// 	}
// 	static setStudentCourses(id) {
// 		return api.get(`/B2BCourse/GetAllCoursesAsStudent/${id}`);
// 	}
// 	static onPauseCourseForStudent(data) {
// 		return api.put(`/studentcourse/pausecourse`, data);
// 	}
// 	static onBlockCourseForStudent(data) {
// 		return api.put(`/studentcourse/blockcourse`, data);
// 	}
// 	static getGroupData(pageNumber, pageSize) {
// 		return api.get(
// 			`/group/pagination?pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 		);
// 	}
// 	static getGroupStudents(groupId) {
// 		return api.get(`/groupstudent/groupId/${groupId}/${true}`);
// 	}
// 	static changeStudentAttachment(data) {
// 		return api.post(`/groupstudent`, data);
// 	}
// 	static getCurators() {
// 		return api.get(
// 			`/user?usertypeId=${CURATOR_USER_TYPE}&pageNumber=1&pageSize=1000`,
// 		);
// 	}
// 	static getOrganizationEducationalLevels() {
// 		return api.get(`/organizationEducationLevel`);
// 	}
// 	static getOrganizationEducationalLevelsById(id) {
// 		return api.get(`/organizationEducationLevel/${id}`);
// 	}
// 	static createGroup(data) {
// 		return api.post(`/group`, data);
// 	}
// 	static updateGroup(data) {
// 		return api.put(`/group`, data);
// 	}
// 	static getGroupById(id) {
// 		return api.get(`/group/${id}`);
// 	}
// 	static getWebinarsByCurator(pageNumber, pageSize) {
// 		return api.get(
// 			`/b2BWebinar/GetByPagination?pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 		);
// 	}
// 	static getGroups() {
// 		return api.get(`/group`);
// 	}
// 	static getLecturersByDisciplineId(disciplineId) {
// 		return api.get(`/subjectLecturer/subjectId/${disciplineId}/${false}`);
// 	}
// 	static createOrgWebinar(data) {
// 		return api.post(`B2BWebinar/create`, data);
// 	}
// 	static updateOrgWebinar(data) {
// 		return api.put(`B2BWebinar/Update`, data);
// 	}
// 	static updateWebinarCalendar(data) {
// 		return api.put(`/b2BWebinar/UpdateWebinarCalendar`, data);
// 	}
// 	static removeCalendarFromCurrentWebinarDay(id) {
// 		return api.delete(`/b2BWebinar/DeleteWebinarCalendar/${id}`);
// 	}
// 	static getStudentsByOrganizationId(id) {
// 		return api.get(`/groupstudent/groupId/${id}/${false}`);
// 	}
// 	static getStudentDataById(id) {
// 		return api.get(`/student/detailswithdocs/${id}`);
// 	}
// 	static getHelpPageContent() {
// 		return api.get(`/page/byname/TR_HELP_PAGE`);
// 	}
// 	static getHelpPageContentBySpecialDomain() {
// 		return api.get(`/page/B2BHelp`);
// 	}
// 	static getPageById(id) {
// 		return api.get(`/page/byId/${id}`);
// 	}
// 	static updateOrgUserPassword(data) {
// 		return api.put(`/user/updatepassword`, data);
// 	}
// 	static updateOrgUserAvatar(data) {
// 		return api.put(`/user`, data);
// 	}
// 	static createOrganization(data) {
// 		return api.post(`/organization/autoregistration`, data);
// 	}
// 	static getPaymentPackages() {
// 		return api.get(
// 			`/paymentPackage/forsale/${
// 				localStorage.getItem(CURRENCY_KEY)
// 					? localStorage.getItem(CURRENCY_KEY)
// 					: DEFAULT_CURRENCY_KEY
// 			}`,
// 		);
// 	}
// 	static updatePaymentPackage(id) {
// 		return api.put(`/organizationPaymentPackage/recurring/${id}`);
// 	}
// 	static getAllPaymentPackages() {
// 		return api.get(
// 			`/paymentPackage/promo/${
// 				localStorage.getItem(CURRENCY_KEY)
// 					? localStorage.getItem(CURRENCY_KEY)
// 					: DEFAULT_CURRENCY_KEY
// 			}`,
// 		);
// 	}
// 	static getPaymentPackageItems(id) {
// 		return api.get(
// 			`/paymentPackage/withprices/${id}/${
// 				localStorage.getItem(CURRENCY_KEY)
// 					? localStorage.getItem(CURRENCY_KEY)
// 					: DEFAULT_CURRENCY_KEY
// 			}`,
// 		);
// 	}
// 	static makeAdepositPaymentPackage(data) {
// 		return api.post(`/organizationPaymentPackage/save`, data);
// 	}
// 	static getPaymentPackageHistory() {
// 		return api.get(
// 			`/organizationPaymentPackage/current/${
// 				localStorage.getItem(CURRENCY_KEY)
// 					? localStorage.getItem(CURRENCY_KEY)
// 					: DEFAULT_CURRENCY_KEY
// 			}`,
// 		);
// 	}
// 	static deleteKuratorWebinar(id) {
// 		return api.delete(`/b2BWebinar/delete/${id}`);
// 	}
// 	static getPaymentLogs(pageNumber, pageSize) {
// 		return api.get(
// 			`/systemlog/localpayments?pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 		);
// 	}
// 	static getBalanceLogs(pageNumber, pageSize) {
// 		return api.get(
// 			`/systemlog/balance?pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 		);
// 	}
// 	static getLogByPaymentId(id) {
// 		return api.get(`/payment/${id}`);
// 	}
// 	static contactUs(data) {
// 		return api.post(`/contactus`, data);
// 	}
// 	static getOnetimeWebinarPageContent() {
// 		return api.get(`/page/byname/TR_CONFERENCE_ROOM_PAGE`);
// 	}
// 	static sendPaymentReceipt(data) {
// 		return api.post(`/organizationPayments`, data);
// 	}
// 	static updatePaymentReceipt(data) {
// 		return api.put(`/organizationPayments`, data);
// 	}
// 	static getPaymentReceipts(receiptType, pageNumber, pageSize) {
// 		return api.get(
// 			`/organizationPayments/${
// 				localStorage.getItem(CURRENCY_KEY)
// 					? localStorage.getItem(CURRENCY_KEY)
// 					: DEFAULT_CURRENCY_KEY
// 			}/${receiptType}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 		);
// 	}
// 	static acceptPaymentReceipt(id) {
// 		return api.put(`/organizationPaymentPackage/confirmpayment/${id}`);
// 	}
// 	static declinePaymentReceipt(data) {
// 		return api.put(`/organizationPayments/reject`, data);
// 	}
// 	static activatePackage(id) {
// 		return api.put(`/organizationPaymentPackage/activatepackage/${id}`);
// 	}
// 	static getWebinarData(id) {
// 		return api.get(`/webinar/webinarCalendarResources/${id}`);
// 	}
// 	static getWebinarVideos(url, webinarCalendarId, userId) {
// 		return axios({
// 			method: "get",
// 			url: `${url}WebinarVideo/get/${webinarCalendarId}/${userId}`,
// 			headers: {
// 				Authorization: localStorage.getItem(TOKEN_KEY),
// 				language: localStorage.getItem(LANGUAGE_KEY),
// 			},
// 		});
// 	}
// 	static getFileLibraryAndStudents() {
// 		return api.get(`/filelibrary/filesandstudents`);
// 	}
// 	static getStudentsByFileIds(data) {
// 		return api.post(`/filelibrary/studentsbyfiles`, data);
// 	}
// 	static getFilesbyStudents(data) {
// 		return api.post(`/filelibrary/filesbystudents`, data);
// 	}
// 	static getFilesToStudents(data) {
// 		return api.post(`/filelibrary/attachfiletostudents`, data);
// 	}
// 	static getOrgAdminInfo() {
// 		return api.get(`/organizationPaymentPackage/datainfo`);
// 	}
// 	static getQuizResults(courseId, studentId) {
// 		return api.get(
// 			`/quizattempt/organization/quizinfo/${courseId}/${studentId}`,
// 		);
// 	}
// 	static getHelpData(key) {
// 		return api.get(`/librarydata/howto?pagename=${key}`);
// 	}
// 	static getCurrentQuestionMarkData(pageName, key) {
// 		return api.get(
// 			`/librarydata/howto/single?pagename=${pageName}&dataname=${key}`,
// 		);
// 	}
// 	static getPaymentHistoryForMindalayAdmin(pageNumber, pageSize, entityType) {
// 		return api.get(
// 			`/payment/mindalayhistory/${entityType}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 		);
// 	}
// 	static getPaymentPackageById(paymentPackageEntityId) {
// 		return api.get(
// 			`/organizationPayments/byId/${paymentPackageEntityId}/${
// 				localStorage.getItem(CURRENCY_KEY)
// 					? localStorage.getItem(CURRENCY_KEY)
// 					: DEFAULT_CURRENCY_KEY
// 			}`,
// 		);
// 	}
// 	static getSystemTranslations(pageNumber, pageSize, isocode, type) {
// 		return api.get(
// 			`/translation/pagination?pageNumber=${pageNumber}&pageSize=${pageSize}&isocode=${isocode}`,
// 		);
// 	}
// 	static getOrganizationTranslations(pageNumber, pageSize, isocode, type) {
// 		return api.get(
// 			`/translation/organization?pageNumber=${pageNumber}&pageSize=${pageSize}&isocode=${isocode}`,
// 		);
// 	}

// 	static getOrganizationTranslationsByOrganizationId(
// 		pageNumber,
// 		pageSize,
// 		isocode,
// 		type,
// 	) {
// 		if (!type) {
// 			return api.get(
// 				`translation/organization?pageNumber=${pageNumber}&pageSize=${pageSize}&isocode=${isocode}`,
// 			);
// 		} else {
// 			return api.get(
// 				`translation/organization?pageNumber=${pageNumber}&pageSize=${pageSize}&isocode=${isocode}&type=${type}`,
// 			);
// 		}
// 	}

// 	static deleteSystemTranslation(id) {
// 		return api.delete(`/translation/${id}`);
// 	}

// 	// static createSystemTranslation(data) {
// 	// 	return api.post(`/translation`, data);
// 	// }

// 	// static updateSystemTranslation(data) {
// 	// 	return api.put(`/translation`, data);
// 	// }

// 	static getSystemTranslationsByType(isocode, type) {
// 		return api.get(`translation?isocode=${isocode}&type=${type}`);
// 	}
// 	static getOraganizationTranslationsByType(isocode, type) {
// 		return api.get(`translation/organization?isocode=${isocode}&type=${type}`);
// 	}

// 	static searchTranslations(keyword, isocode, type = null) {
// 		return api.get(`/translation/filter?keyword=${keyword}&isocode=${isocode}`);
// 	}
// 	static createTransalation(data) {
// 		return api.post(`/translation`, data);
// 	}
// 	static updateTransalation(data) {
// 		return api.put(`/translation`, data);
// 	}
// 	static updateOrganizationTransalation(data) {
// 		return api.put(`/translation/organization`, data);
// 	}

// 	//Document
// 	static createDocument(formData) {
// 		return api.post(`/organizationDocument`, formData);
// 	}
// 	static updateDocument(formData) {
// 		return api.put(`/organizationDocument`, formData);
// 	}
// 	static getDocumentTemplateInfoData(templateInfoId) {
// 		return api.get(
// 			`/documentTemplateInfoData?templateInfoId=${templateInfoId}`,
// 		);
// 	}
// 	static getOrganizationDocument(categoryId) {
// 		return api.get(`/organizationDocument?categoryId=${categoryId}`);
// 	}
// 	static getDocumentByIdAsStudent(documentId) {
// 		return api.get(`/organizationDocument/studentdoc/${documentId}`);
// 	}
// 	static getDocumentByIdAsLecturer(documentId) {
// 		return api.get(`/organizationDocument/lecturerdoc/${documentId}`);
// 	}
// 	static getDocumentByIdAsCurator(documentId) {
// 		return api.get(`/organizationDocument/organizationDocument/${documentId}`);
// 	}
// 	static getStudentDocumentsByStudent() {
// 		return api.get(`/organizationDocument/student`);
// 	}
// 	static getStudentDocumentsByLecturer(userId) {
// 		return api.get(`/organizationDocument/lecturer/${userId}`);
// 	}
// 	static getStudentDocumentsByCurator(userId) {
// 		return api.get(`/organizationDocument/curator/${userId}`);
// 	}
// 	static deleteDocument(documentId) {
// 		return api.delete(`/organizationDocument/${documentId}`);
// 	}
// 	static getOrgAndGroupname() {
// 		return api.get(`/groupstudent/organdgroupname`);
// 	}
// 	static documentSign(formData) {
// 		return api.put(`/organizationDocument/sign`, formData);
// 	}
// 	static courseCopy(courseId) {
// 		return api.post(`/B2BCourse/Copy/${courseId}`);
// 	}
// 	static getPackageProperties() {
// 		return api.get(`/packageProperty`);
// 	}
// 	static calculateCustomPaymentPackagePrice(data) {
// 		return api.post(`/packageProperty/calculate`, data);
// 	}
// 	static createCustomPaymentPackage(data) {
// 		return api.post(`/packageProperty/create`, data);
// 	}
// 	static getCourseByIdFromOrgAdmin(id) {
// 		return api.get(`/B2BCourse/GetAsAdmin/${id}`);
// 	}
// 	static getProgramsAsAdmin() {
// 		return api.get(`/division/GetEducationalUnitsOfOrganization`);
// 	}
// 	static getSubjectsAsAdmin(id) {
// 		return api.get(
// 			`/organizationEducationLevel/subjectbyprogramname/asadmin/${id}`,
// 		);
// 	}
// 	static getCoursesBySubjectId(id) {
// 		return api.get(`/B2BCourse/GetAllCoursesBySubjectAsAdmin/${id}`);
// 	}
// 	static createCoursePriceAsAdmin(data) {
// 		return api.post(`/innerPayment`, data);
// 	}
// 	static updateCoursePriceAsAdmin(data) {
// 		return api.put(`/innerPayment`, data);
// 	}
// 	static getInnerPaymentAsAdmin(id) {
// 		return api.get(`/innerPayment/bycourse/${id}`);
// 	}
// 	static payLessThanFullPriceForCourse(data) {
// 		return api.post(`/studentInnerPayment`, data);
// 	}
// 	static payFullPriceForCourse(data) {
// 		return api.post(`/studentInnerPayment/fullpayment`, data);
// 	}
// 	static getPaymentSchedule(innerPaymentId) {
// 		return api.get(`/studentInnerPayment/schedule/${innerPaymentId}`);
// 	}
// 	static getPaymentScheduleForOrgAdmin(courseId, studentId) {
// 		return api.get(
// 			`/studentInnerPayment/bycourseandstudent/${courseId}/${studentId}`,
// 		);
// 	}
// 	static getPaymentSchedules() {
// 		return api.get(`/innerPayment/bystudent`);
// 	}
// 	static createDeposit(data) {
// 		return api.post(`/payment/deposit`, data);
// 	}
// 	static getCheckedquestions(attemptId) {
// 		return api.get(`/quizattempt/checkedquestions/${attemptId}`);
// 	}
// 	static getLibraryDataByPageName(pageName) {
// 		return api.get(`/librarydata/page_helps?pageName=${pageName}`);
// 	}
// 	static getLeaderbordInformation(type, data) {
// 		return api.post(`/${type}/leaderboardinfo`, data);
// 	}

// 	// Structure
// 	//
// 	//Divisions
// 	static getDivisions(pageNumber = 1, pageSize = 10, ownerId) {
// 		if (ownerId) {
// 			return api.get(
// 				`division/getOwnerDivisionsByPagination?pageNumber=${pageNumber}&pageSize=${pageSize}&ownerId=${ownerId}`,
// 			);
// 		}
// 		return api.get(
// 			`division/getOwnerDivisionsByPagination?pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 		);
// 	}
// 	static getAllDivisions(ownerId) {
// 		return api.get(`division/getOwnerDivisionsForParenting?ownerId=${ownerId}`);
// 	}
// 	static getDivisionsById(divisionId) {
// 		return api.get(`division/get/${divisionId}`);
// 	}
// 	static deleteDivision(divisionId) {
// 		return api.delete(`division/delete/${divisionId}`);
// 	}
// 	static createDivisions(data) {
// 		return api.post(`division/create`, data);
// 	}
// 	static updateDivision(data) {
// 		return api.put(`division/update`, data);
// 	}
// 	static getDivisionsStudents(id, isGrupeStudents = true) {
// 		return api.get(
// 			`divisionStudent/getStudentsByDivision/${id}/${isGrupeStudents}`,
// 		);
// 	}
// 	static setDivisionsStudents(data) {
// 		return api.post(`divisionStudent/save`, data);
// 	}
// 	static getOwnerDivisionsForWebinars(divisionId) {
// 		if (divisionId) {
// 			return api.get(
// 				`/division/GetOwnerDivisionsForWebinars?divisionId=${divisionId}`,
// 			);
// 		} else {
// 			return api.get(`/division/GetOwnerDivisionsForWebinars`);
// 		}
// 	}
// 	static getEducationalUnitsOfLecturer(educationalUnitId) {
// 		if (educationalUnitId) {
// 			return api.get(
// 				`/division/GetEducationalUnitsOfLecturer?educationalUnitId=${educationalUnitId}`,
// 			);
// 		} else {
// 			return api.get(`/division/GetEducationalUnitsOfLecturer`);
// 		}
// 	}
// 	//

// 	// EducationalUnits
// 	static getEducationalUnits(pageNumber = 1, pageSize = 10) {
// 		return api.get(
// 			`educationalUnit/getEducationalUnitsByPagination?pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 		);
// 	}
// 	static getAllEducationalUnits() {
// 		return api.get(`educationalUnit/GetEducationalUnitsForParenting`);
// 	}
// 	static getEducationalUnitsById(educationalUnitId) {
// 		return api.get(`educationalUnit/get/${educationalUnitId}`);
// 	}
// 	static getReplacementLecturers(lecturerUserId, subjectId) {
// 		return api.get(
// 			`educationalUnitLecturer/getReplacementLecturers/${lecturerUserId}/${subjectId}`,
// 		);
// 	}
// 	static getOwnerDivisions() {
// 		//todo
// 		return api.get(`division/getOwnerDivisions`);
// 	}
// 	static getDivisionById(divisionId) {
// 		return api.get(`division/get/${divisionId}`);
// 	}
// 	static deleteEducationalUnit(divisionId) {
// 		return api.delete(`educationalUnit/delete/${divisionId}`);
// 	}
// 	static createEducationalUnit(data) {
// 		return api.post(`/educationalUnit/Create`, data);
// 	}
// 	static updateEducationalUnit(data) {
// 		return api.put(`educationalUnit/update`, data);
// 	}
// 	static getEducationalDivisions(id, isActiv = true) {
// 		return api.get(
// 			`educationalUnitDivision/getDivisionsByEducationalUnit/${id}/${isActiv}`,
// 		);
// 	}
// 	static setEducationalDivisions(data) {
// 		return api.post(`educationalUnitDivision/save`, data);
// 	}
// 	static getEducationalLecturers(id, isActiv = true) {
// 		return api.get(
// 			`educationalUnitLecturer/getLecturersByEducationalUnit/${id}/${isActiv}`,
// 		);
// 	}
// 	static setEducationalLecturers(data) {
// 		return api.post(`educationalUnitLecturer/save`, data);
// 	}
// 	//
// 	//Settings
// 	//----------------------------------------------------------------------------------
// 	static getOrganizationSetting = shortname => {
// 		return api.get(`organizationSetting/${shortname}`);
// 	};
// 	static createSettings = data => {
// 		return api.post(`organizationSetting/createWhiteLabelSettings`, data);
// 	};
// 	static updateSettings = data => {
// 		return api.put(`organizationSetting/updateWhiteLabelSettings`, data);
// 	};
// 	static getCurrentOrganizationSettingsAdmin = id => {
// 		return api.get(
// 			`organizationSetting/getCurrentOrganizationSettingsAdmin/${id}`,
// 		);
// 	};
// 	static updateSettingsWhiteLabelMediaSettings = data => {
// 		return api.put(`organizationSetting/updateWhiteLabelMediaSettings`, data);
// 	};
// 	static updateSettingsWhiteLabelColors = data => {
// 		return api.put(`organizationSetting/UpdateWhiteLabelColorSettings`, data);
// 	};
// 	static getSettingByShortName = shortname => {
// 		return api.get(
// 			`organizationSetting/GetByOrganizationShortName/${shortname}`,
// 		);
// 	};
// 	static getCurrentOrganizationSettings = () => {
// 		return api.get(`organizationSetting/getCurrentOrganizationSettings`);
// 	};

// 	static checkWebinarDays = (url, data) => {
// 		return api.post(`/${url}`, data);
// 	};

// 	static getCourseStudentsByCourseId(courseId) {
// 		return api.get(`/studentcourse/coursestudents/${courseId}`);
// 	}
// 	static getOrganizationInnerPaymentContract() {
// 		return api.get(`organizationInnerPaymentContract/getbalance`);
// 	}
// 	static getProgramsOfLecturer = () => {
// 		return api.get(`EducationalUnit/GetProgramsOfLecturer`);
// 	};
// 	static createCourseAssignment(data) {
// 		return api.post(`assignment/createCourseAssignment`, data);
// 	}
// 	static deleteAssignment(id) {
// 		return api.delete(`assignment/deleteAssignment/${id}`);
// 	}
// 	static createTopicAssignment(data) {
// 		return api.post(`assignment/createTopicAssignment`, data);
// 	}
// 	static updateCourseAssignment(data) {
// 		return api.put(`assignment/updateCourseAssignment`, data);
// 	}
// 	static updateTopicAssignment(data) {
// 		return api.put(`assignment/updateTopicAssignment`, data);
// 	}
// 	static getAssignmentByLecturerId(id) {
// 		return api.get(`/assignment/getAssignmentByLecturer/${id}`);
// 	}
// 	static getQuizzesAssignment(id) {
// 		return api.get(`/assignment/GetTopicAssignment?entityId=${id}`);
// 	}
// 	static getAssignmentByCourseId(id) {
// 		return api.get(`/assignment/GetCourseAssignment?entityId=${id}`);
// 	}

// 	// static getQuizzesAssignment(id) {
// 	// 	return api.get(
// 	// 		`/assignment/getAssignmentByType?entitytype=1&entityId=${id}`,
// 	// 	);
// 	// }
// 	// static getAssignmentByCourseId(id) {
// 	// 	return api.get(
// 	// 		`/assignment/getAssignmentByType?entitytype=0&entityId=${id}`,
// 	// 	);
// 	// }
// 	static getStudentAssignment(id) {
// 		return api.get(`studentAssignment/getStudentAssignment?id=${id}`);
// 	}
// 	static getStudentAssignmentById(id) {
// 		return api.get(`studentAssignment/getStudentAssignmentById/${id}`);
// 	}
// 	static createStudentAssignment(data) {
// 		return api.post(
// 			`studentAssignment/createStudentAssignmentResultByStudent`,
// 			data,
// 		);
// 	}
// 	static createLecturerAssignment(data) {
// 		return api.post(
// 			`studentAssignment/createStudentAssignmentResultByLecturer`,
// 			data,
// 		);
// 	}
// 	static getLecturerAssignments(courseId) {
// 		return api.get(`assignment/getAssignmentsOfCourse?courseId=${courseId}`);
// 	}
// 	static GetStudentAssignmentByLecturer(id, studentId) {
// 		return api.get(
// 			`studentAssignment/getStudentAssignmentByLecturer?id=${id}&studentId=${studentId}`,
// 		);
// 	}
// 	static markAssignmentAsComplete(data) {
// 		return api.post(`studentAssignment/MarkAssignmentAsComplete`, data);
// 	}
// 	static getByDivision(divisionId) {
// 		return api.get(`B2BWebinar/GetByDivision?divisionId=${divisionId}`);
// 	}
// 	static getByOwner(ownerId) {
// 		return api.get(`B2BWebinar/GetByOwner?ownerId=${ownerId}`);
// 	}
// 	//developer
// 	static getUsertypes(orgId) {
// 		if (orgId) {
// 			return api.get(`usertype?orgId=${orgId}`);
// 		}
// 		return api.get(`usertype`);
// 	}
// 	static getUserTypeById(id) {
// 		return api.get(`usertype/${id}`);
// 	}
// 	static createUserType(data) {
// 		return api.post(`usertype`, data);
// 	}
// 	static updateUserType(data) {
// 		return api.put(`usertype`, data);
// 	}
// 	static getDefaultUserType() {
// 		return api.get(`usertype/default`);
// 	}
// 	static deleteUsertype(id) {
// 		return api.delete(`usertype/${id}`);
// 	}
// 	static getOrganizations() {
// 		return api.get(`organization`);
// 	}
// 	static getPageusertype(usertypeId) {
// 		return api.get(
// 			`pageusertype/page?usertypeId=${usertypeId}&frontendpage=false`,
// 		);
// 	}
// 	static postPageusertype(data) {
// 		return api.post(`pageusertype`, data);
// 	}
// 	static getUsers(pageNumber, usertypeId = null, pageSize = 10) {
// 		if (usertypeId) {
// 			return api.get(
// 				`user?usertypeId=${usertypeId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
// 			);
// 		}
// 		return api.get(`user?pageNumber=${pageNumber}&pageSize=${pageSize}`);
// 	}
// 	static getUser(userId) {
// 		return api.get(`user/${userId}`);
// 	}
// 	static createUser(data) {
// 		return api.post(`user`, data);
// 	}
// 	static updateUser(data) {
// 		return api.put(`user`, data);
// 	}
// 	static user_activate(userId, active) {
// 		return api.post(`user/activate/${userId}/${active}`);
// 	}
// 	static getTimeZone() {
// 		return api.get(`librarydata/time_zones`);
// 	}
// 	static filterUsers(data) {
// 		return api.post(`user/filter`, data);
// 	}
// 	static getStudents() {
// 		return api.get(`/student`);
// 	}
// 	static createStudent(data) {
// 		return api.post(`/student`, data);
// 	}
// 	static updateStudent(data) {
// 		return api.put(`/student`, data);
// 	}
// 	static studentBulkUpload(data) {
// 		return api.post(`student/studentbulkupload`, data);
// 	}

// 	static getStudentById(studentId) {
// 		return api.get(`/student/${studentId}`);
// 	}
// 	static getStudentByGrup(grupId) {
// 		return api.get(`groupstudent/groupId/${grupId}/false`);
// 	}
// 	static getPagerole(pageId, usertypeId) {
// 		return api.get(`pagerole?pageId=${pageId}&usertypeId=${usertypeId}`);
// 	}
// 	static changeUserTypeRole(data) {
// 		return api.post(`usertyperole`, data);
// 	}
// 	static getDeveloperPaymentPackages() {
// 		return api.get(`paymentpackage`);
// 	}
// 	static getDeveloperPaymentPackage(paymentId) {
// 		return api.get(`paymentpackage/${paymentId}`);
// 	}
// 	static createDeveloperPaymentPackage(data) {
// 		return api.post(`paymentpackage`, data);
// 	}
// 	static updateDeveloperPaymentPackage(data) {
// 		return api.put(`paymentpackage`, data);
// 	}
// 	static deletePaymentpackage(paymentId) {
// 		return api.delete(`paymentpackage/${paymentId}`);
// 	}
// 	static getRoles() {
// 		return api.get(`role`);
// 	}
// 	static createRole(data) {
// 		return api.post(`role`, data);
// 	}
// 	static updateRole(data) {
// 		return api.put(`role`, data);
// 	}
// 	static deleteRole(id) {
// 		return api.delete(`role/${id}`);
// 	}

// 	static getConfig() {
// 		return api.get(`config`);
// 	}
// 	static getConfigById(configId) {
// 		return api.get(`config/${configId}`);
// 	}
// 	static createConfig(data) {
// 		return api.post(`config`, data);
// 	}
// 	static updateConfig(data) {
// 		return api.put(`config`, data);
// 	}
// 	static deleteConfig(configId) {
// 		return api.delete(`config/${configId}`);
// 	}
// 	static getPageContentByLang(langId) {
// 		return api.get(`pageContent/bylang/${langId}`);
// 	}
// 	static getPageContentByLangByID(langId, pageContentId) {
// 		return api.get(
// 			`pageContent/getbylang?id=${pageContentId}&langId=${langId}`,
// 		);
// 	}
// 	static deletePageContent(pageContentId) {
// 		return api.delete(`pageContent/${pageContentId}`);
// 	}
// 	static creataPageContent(data) {
// 		return api.post(`pageContent`, data);
// 	}
// 	static updatePageContent(data) {
// 		return api.put(`pageContent`, data);
// 	}

// 	static getForcontent() {
// 		return api.get("page/getforcontent");
// 	}

// 	static getCategories(type = null) {
// 		if (type !== null) {
// 			return api.get(`category/bytype/${type}`);
// 		}
// 		return api.get(`category`);
// 	}
// 	static createCategory(data) {
// 		return api.post(`category`, data);
// 	}
// 	static updateCategory(data) {
// 		return api.put(`category`, data);
// 	}
// 	static deleteCategory(id) {
// 		return api.delete(`category/${id}`);
// 	}
// 	static getLibraryDataByIso(isoCode) {
// 		return api.get(`library/byiso?isocode=${isoCode}`);
// 	}
// 	static getLibraryDataByIsoAndLibId(isoCode, libId) {
// 		return api.get(`librarydata/byiso?libId=${libId}&isocode=${isoCode}`);
// 	}
// 	static createLibrary(data) {
// 		return api.post(`library`, data);
// 	}
// 	static updateLibrary(data) {
// 		return api.put(`library`, data);
// 	}
// 	static deleteLibrary(id) {
// 		return api.delete(`library/${id}`);
// 	}
// 	static createLibrarydata(data) {
// 		return api.post(`librarydata`, data);
// 	}
// 	static updateLibrarydata(data) {
// 		return api.put(`librarydata`, data);
// 	}
// 	static deleteLibrarydata(id) {
// 		return api.delete(`librarydata/${id}`);
// 	}
// 	static getLibrarydataByLibId(libId) {
// 		return api.get(`librarydata?libId=${libId}`);
// 	}
// 	static getWhitelabelLanguages() {
// 		return api.get(`language/whitelabel`);
// 	}
// 	static getDocumentCategory() {
// 		return api.get("documentCategory");
// 	}
// 	static createDocumentCategory(data) {
// 		return api.post("documentCategory", data);
// 	}
// 	static updateDocumentCategory(data) {
// 		return api.put("documentCategory", data);
// 	}
// 	static deleteDocumentCategory(documentCategoryId) {
// 		return api.delete(`documentCategory/${documentCategoryId}`);
// 	}

// 	static getDocumentTemplateInfoDatas() {
// 		return api.get(`documentTemplateInfoData`);
// 	}
// 	static getDocumentTemplateById(id = null) {
// 		return api.get(`documentTemplate/${id}`);
// 	}

// 	static getDocumentTemplate() {
// 		return api.get(`documentTemplate`);
// 	}

// 	static getDocumentTemplateInfo() {
// 		return api.get(`documentTemplateInfo`);
// 	}
// 	static createDocumentTemplateInfo(data) {
// 		return api.post(`documentTemplateInfo`, data);
// 	}
// 	static updateDocumentTemplateInfo(data) {
// 		return api.put(`documentTemplateInfo`, data);
// 	}
// 	static getDocumentTemplateInfoInTemplateId(templateId) {
// 		return api.get(`documentTemplateInfo?templateId=${templateId}`);
// 	}

// 	static getDocumentTemplateInfoDataInTemplateId(templateId) {
// 		return api.get(`documentTemplateInfoData?templateId=${templateId}`);
// 	}
// 	static createDocumentTemplateInfoData(data) {
// 		return api.post(`documentTemplateInfoData`, data);
// 	}
// 	static updateDocumentTemplateInfoData(data) {
// 		return api.put(`documentTemplateInfoData`, data);
// 	}

// 	static getLibrary() {
// 		return api.get(`library`);
// 	}
// 	static getDocumentTemplate() {
// 		return api.get(`documentTemplate`);
// 	}
// 	static createDocumentTemplate(data) {
// 		return api.post(`documentTemplate`, data);
// 	}
// 	static updateDocumentTemplate(data) {
// 		return api.put(`documentTemplate`, data);
// 	}
// 	static getLectureQuizAtetmpt(quizId, studentId) {
// 		return api.get(`quizattempt/${quizId}/${studentId}`);
// 	}
// 	static getLectureQuiz(quizId, studentId) {
// 		return api.get(`courseQuiz/lecturer/${quizId}/${studentId}`);
// 	}
// 	static getPaymentSystemPackages() {
// 		return api.get(`paymentSystem/package`);
// 	}
// 	static getPaymentSystemOrganization() {
// 		return api.get(`paymentSystem/organization`);
// 	}
// 	static createPaymentSystemsOrganization(id) {
// 		return api.post(`paymentSystemConfig/organization/${id}`);
// 	}
// 	static deletePaymentSystemsOrganization(id) {
// 		return api.delete(`paymentSystemConfig/organization/${id}`);
// 	}
// static sendAltValues(data) {
// 	return api.post(`fileLibrary/getfilepaths`, data);
// }
// 	// paymentSystems/package
// }

// export default ApiService;

//-----------

import axios from "axios";
import AlertService from "./alertService";
import {
	CURATOR_USER_TYPE,
	CURRENCY_KEY,
	DEFAULT_LANGUAGE_KEY,
	ERROR_KEY,
	LANGUAGE_KEY,
	REFRESH_TOKEN_KEY,
	TOKEN_KEY,
	USER_KEY,
	DEFAULT_CURRENCY_KEY,
	BACK_URL_KEY,
	STUDENT_USER_TYPE,
	LECTURER_USER_TYPE,
} from "../Constants/mainKeys";
import { VIDEO_PROFESSIONALISM_PATH_KEY } from "../Constants/requestKeys";

const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	withCredentials: true,
});

const identityApi = axios.create({
	baseURL: process.env.REACT_APP_IDENTITY_API_URL,
});

identityApi.interceptors.request.use(async config => {
	config.headers = {
		"ngrok-skip-browser-warning": true,
	};
	return config;
});

api.interceptors.request.use(async config => {
	config.headers = {
		Authorization: localStorage.getItem(TOKEN_KEY)
			? localStorage.getItem(TOKEN_KEY)
			: "",
		language: localStorage.getItem(LANGUAGE_KEY),
		currency: localStorage.getItem(CURRENCY_KEY)
			? localStorage.getItem(CURRENCY_KEY)
			: DEFAULT_CURRENCY_KEY,

		"ngrok-skip-browser-warning": true,
	};
	return config;
});

const showError = errorMsg => {
	errorMsg && AlertService.alert(ERROR_KEY, errorMsg);
};

// const ServerErrorText = `
//   <p>Sorry, something went wrong! Check your internet connection, if the problem still persists,
//    <a
//     id="contact-us"
//     href=/${localStorage.getItem(LANGUAGE_KEY) ? localStorage.getItem(LANGUAGE_KEY) : DEFAULT_LANGUAGE_KEY}/contact-us
//     class="contact-us"
//      >contact us .</a>
//   </p>
// `;

// const removeAlertBlock = () => {
//   var currentBlock = document.getElementById("alertBlockCloseButton");
//   if (currentBlock && currentBlock.parentNode) {
//     currentBlock.parentNode.removeChild(currentBlock);
//   } else { return; }
// }

api.interceptors.response.use(
	response => {
		// if (response && response.data && response.data?.status === 500) {
		//   AlertService.alert(ERROR_KEY, serverErrorText, true);
		//   let alertBlock = document.getElementById("alertBlockCloseButton");
		//   if (alertBlock) {
		//     let contactUs = alertBlock.querySelector("#contact-us");
		//     contactUs && contactUs.addEventListener("click", function () {
		//       removeAlertBlock();
		//     });
		//   }
		// }

		if (response && response.data && response.data?.status >= 500) {
			if (response.data?.message) {
				return Promise.reject(response.data?.message);
			}
			return Promise.reject("Sorry, something went wrong!");
		}

		if (response && response.data) {
			if (
				response.data.respmess === "User is not Authenticated" ||
				response.data.respmess === "This course is not belong to you!"
			) {
				window.location.href = `/${
					localStorage.getItem(LANGUAGE_KEY)
						? localStorage.getItem(LANGUAGE_KEY)
						: DEFAULT_LANGUAGE_KEY
				}`;
				localStorage.clear();
			}
			return !response.data.respcode
				? response.data
				: Promise.reject({
						respmess: response.data.respmess,
						respcode: response.data.respcode,
				  });
		}
	},
	error => {
		if (error.response && error.response.status === 401) {
			const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
			const user = localStorage.getItem(USER_KEY);
			const token = localStorage.getItem(TOKEN_KEY);
			if (!refreshToken || !user || !token) {
				logout();
			} else {
				getUserDataByRefreshToken(refreshToken, user);
			}
		}
		if (error.response) {
			const errors = error.response.data.errors;
			ApiService.getValidationTranslations()
				.then(response => {
					if (response && response.data) {
						for (const err in errors) {
							const isExistErrorText = response.data.find(
								error => error.shortKey === errors[err][0],
							);
							if (isExistErrorText) {
								showError(isExistErrorText.translation1);
							} else {
								showError(errors[err][0]);
							}
						}
					} else {
						window.location.href = `/${
							localStorage.getItem(LANGUAGE_KEY)
								? localStorage.getItem(LANGUAGE_KEY)
								: DEFAULT_LANGUAGE_KEY
						}/under-maintenance`;
					}
				})
				.catch(() => {
					window.location.href = `/${
						localStorage.getItem(LANGUAGE_KEY)
							? localStorage.getItem(LANGUAGE_KEY)
							: DEFAULT_LANGUAGE_KEY
					}/under-maintenance`;
				});
			return Promise.reject(errors || error.response.data.ExcMessage || error);
		} else {
			// Server error
			if (!window.location.href.includes("under-maintenance")) {
				localStorage.setItem(BACK_URL_KEY, window.location.pathname);
				window.location.href = `/${
					localStorage.getItem(LANGUAGE_KEY)
						? localStorage.getItem(LANGUAGE_KEY)
						: DEFAULT_LANGUAGE_KEY
				}/under-maintenance`;
			}
			// return Promise.reject();
		}
	},
);

const getUserDataByRefreshToken = (refreshToken, user) => {
	const formData = new FormData();
	formData.append(`refreshToken`, refreshToken);
	ApiService.getUserDataByRefreshToken(formData)
		.then(response => {
			if (response.data && response.data.accessToken) {
				const data = { ...response.data };
				localStorage.setItem(TOKEN_KEY, data?.accessToken);
				localStorage.setItem(REFRESH_TOKEN_KEY, data?.refreshToken);
				localStorage.setItem(USER_KEY, user);
			} else {
				logout();
			}
		})
		.catch(error => getFail(error));
};

const getFail = error => {
	if (error) {
		window.location.href = `/${
			localStorage.getItem(LANGUAGE_KEY)
				? localStorage.getItem(LANGUAGE_KEY)
				: DEFAULT_LANGUAGE_KEY
		}`;
		localStorage.clear();
	}
};

const logout = () => {
	window.location.href = `/${
		localStorage.getItem(LANGUAGE_KEY)
			? localStorage.getItem(LANGUAGE_KEY)
			: DEFAULT_LANGUAGE_KEY
	}`;
	localStorage.clear();
};

class ApiService {
	static checkServerState() {
		return api.get(`/Site/IsAlive`);
	}

	// RefreshToken
	static getUserDataByRefreshToken(data) {
		return identityApi.post(`/identity/renewToken`, data);
	}

	// Translations
	static getAllTranslations() {
		const data = {
			isocode: `${localStorage.getItem(LANGUAGE_KEY)}`,
			types: [0, 2, 3, 7, 8],
		};
		return api.post(`/translation/systemlangchange`, data);
	}
	static getValidationTranslations() {
		return api.get(
			`/translation?isocode=${localStorage.getItem(LANGUAGE_KEY)}&type=3`,
		);
	}
	static getExeptionTranslations() {
		return api.get(
			`/translation?isocode=${localStorage.getItem(LANGUAGE_KEY)}&type=4`,
		);
	}

	// Users
	static currentUser() {
		return api.get(`/user/current`);
	}
	static getUserById(id) {
		return api.get(`/user/${id}`);
	}
	static userUpdate(data) {
		return api.put(`/user`, data);
	}
	static changePassword(data) {
		return api.put(`/user/updatecredentials`, data);
	}
	static updateCurrentUserData(data) {
		return api.put(`/user/profile`, data);
	}
	static getResetLink(data) {
		return api.post(`user/changecredentialslink?email=${data}`);
	}
	static checkUserByGuid(guid) {
		return api.get(`/user/changecredentials/${guid}`);
	}
	static resetPassword(data) {
		return api.put(`/user/updatecredentials`, data);
	}
	static registration(data) {
		return api.post(`/user/selfregistration`, data);
	}
	static confirmEmailAndLogin(username) {
		return api.post(`/user/emailactivation/${username}`);
	}

	// Languages
	static getLanguages() {
		return api.get(`/language`);
	}
	static createLanguage(data) {
		return api.post(`/language`, data);
	}
	static updateLanguage(data) {
		return api.put(`/language`, data);
	}
	static deleteLanguage(id) {
		return api.delete(`/language/${id}`);
	}

	static getContentLanguages() {
		return api.get(`/library/languages`);
	}
	static getlanguageLevelsData() {
		return api.get(`/library/byname/language_levels`);
	}

	// Courses
	static getCourses() {
		return api.get(`/course`);
	}
	static getPublishedCourses() {
		return api.get(
			`/course/published/${
				localStorage.getItem("currency")
					? localStorage.getItem("currency")
					: "USD"
			}`,
		);
	}
	static getHomeCourses() {
		return api.get(
			`/course/home/${
				localStorage.getItem("currency")
					? localStorage.getItem("currency")
					: "USD"
			}`,
		);
	}
	static getTopCourses() {
		return api.get(
			`/course/top/${
				localStorage.getItem("currency")
					? localStorage.getItem("currency")
					: "USD"
			}`,
		);
	}
	static getRecommendedCourses() {
		return api.get(
			`/course/recommended/${
				localStorage.getItem("currency")
					? localStorage.getItem("currency")
					: "USD"
			}`,
		);
	}
	static getMyCoursesAsInstructor() {
		return api.get(`/course/mycoursesasinstructor`);
	}
	static getMyCoursesAsOrgUser() {
		return api.get(`/B2BCourse/GetLecturerCourses`);
	}
	static getMyCoursesAsStudent() {
		return api.get(
			`/course/mycoursesasstudent/${
				localStorage.getItem("currency")
					? localStorage.getItem("currency")
					: "USD"
			}`,
		);
	}
	static getMyCoursesAsOrgStudent() {
		return api.get(`/B2BCourse/GetAllMyCoursesByStudent`);
	}
	static getCourseById(id) {
		return api.get(`/course/${id}`);
	}
	static getCourseByIdByOrgUser(id) {
		return api.get(`/course/${id}`);
	}
	static getCourseByIdWithOrgUser(id) {
		return api.get(`/B2BCourse/GetCoursesForLecturer/${id}`);
	}
	static getBuyNowCourse(id) {
		return api.get(
			`/course/buynow/${id}/${
				localStorage.getItem("currency")
					? localStorage.getItem("currency")
					: "USD"
			}`,
		);
	}
	static getCourseByIdForAdmin(id) {
		return api.get(`/course/admin/${id}`);
	}
	static getCourseQuizzes(id) {
		return api.get(`/coursequiz?entitytype=0&entityId=${id}`);
	}
	static getPublishedCourseById(id) {
		return api.get(
			`/course/published/${
				localStorage.getItem("currency")
					? localStorage.getItem("currency")
					: "USD"
			}/${id}`,
		);
	}
	static getPublishedCourseByIdByOrgUser(id) {
		return api.get(`/B2BCourse/GetPublished/${id}`);
	}
	static courseDelete(id) {
		return api.delete(`/course/${id}`);
	}
	static courseDeleteByOrgUser(id) {
		return api.delete(`B2BCourse/Delete/${id}`);
	}
	static courseCreate(data) {
		return api.post(`/course`, data);
	}
	static createCourseByOrgUser(data) {
		return api.post(`/B2BCourse/Create`, data);
	}
	static updateCourseByOrgUser(data) {
		return api.put(`/B2BCourse/Update`, data);
	}
	static courseUpdate(data) {
		return api.put(`/course`, data);
	}
	static courseCostUpdate(data) {
		return api.put(`/course/cost`, data);
	}
	static courseTargetStudentsUpdate(data) {
		return api.put(`/course/targetstudents`, data);
	}
	static courseMessagesUpdate(data) {
		return api.put(`/course/messages`, data);
	}
	static submitForVerification(id) {
		return api.put(`/course/submitforverification/${id}`);
	}
	static coursePublish(id) {
		return api.put(`/course/publish/${id}`);
	}
	static coursePublishByOrgUser(id) {
		return api.put(`/studentcourse/publishcourse/${id}`);
	}
	static courseUnPublishByOrgUser(id) {
		return api.put(`/studentcourse/publishcourse/${id}`);
	}
	static courseFilter(data) {
		return api.post(
			`/course/filter/${
				localStorage.getItem("currency")
					? localStorage.getItem("currency")
					: "USD"
			}`,
			data,
		);
	}
	static attachStudentToCourse(data) {
		return api.post(`/studentcourse`, data);
	}
	static buyCoursesFromShoppingCart(data) {
		return api.post(`/studentcourse/shoppingcart`, data);
	}
	static getCoursePrices() {
		return api.get(`/library/byname/price`);
	}
	static getPricesByCurrencyId(id) {
		return api.get(`/librarydata/prices/${id}`);
	}
	static buyCourse(entityType, entityId) {
		return api.post(`/payment`, { entityType, entityId });
	}
	static getVerifiedCourses(pageNumber, pageSize) {
		return api.get(
			`/course/verified?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}
	static getUnverifiedCourses(pageNumber, pageSize) {
		return api.get(
			`/course/unverified?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}
	static getCoursesForDiscount(pageNumber, pageSize) {
		return api.get(
			`/course/discount?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}
	static courseDiscount(data) {
		return api.put(`/course/discount`, data);
	}
	static courseVerify(courseId) {
		return api.put(`/course/verify/${courseId}`);
	}
	static denyCourse(data) {
		return api.put(`/course/denyverification`, data);
	}

	// Sections
	static sectionDelete(id) {
		return api.delete(`/course/section/${id}`);
	}
	static sectionDeleteByOrgUser(id) {
		return api.delete(`/B2BCourse/DeleteSection/${id}`);
	}
	static sectionCreate(data) {
		return api.post(`/course/section`, data);
	}
	static sectionUpdate(data) {
		return api.put(`/course/section`, data);
	}
	static sectionCreateByOrgUser(data) {
		return api.post(`/B2BCourse/CreateSection`, data);
	}
	static sectionUpdateByOrgUser(data) {
		return api.put(`/B2BCourse/UpdateSection`, data);
	}
	static setSectionOrder(dropId, dragId) {
		return api.put(`/course/sectionchangeorder/${dropId}/${dragId}`);
	}
	static setSectionOrderByOrgUser(dropId, dragId) {
		return api.put(`/B2BCourse/ChangeSectionOrder/${dropId}/${dragId}`);
	}

	// Topics
	static topicDelete(id) {
		return api.delete(`/course/topic/${id}`);
	}
	static topicDeleteByOrgUser(id) {
		return api.delete(`/B2BCourse/DeleteTopic/${id}`);
	}
	static topicCreate(data) {
		return api.post(`/course/topic`, data);
	}
	static topicCreateByOrgUser(data) {
		return api.post(`B2BCourse/CreateTopic`, data);
	}
	static topicUpdate(data) {
		return api.put(`/course/topic`, data);
	}
	static topicUpdateByOrgUser(data) {
		return api.put(`/B2BCourse/UpdateTopic`, data);
	}
	static attachFileToTopic(data) {
		return api.post(`/courseTopicFile`, data);
	}
	static attachFileToTopicOrg(data) {
		return api.post(`/courseTopicFile/organization`, data);
	}
	static updateAttachedFileToTopic(data) {
		return api.put(`/courseTopicFile`, data);
	}
	static updateAttachedFileToTopicOrg(data) {
		return api.put(`/courseTopicFile/organization`, data);
	}
	static getAttachFileToTopicById(id) {
		return api.get(`/courseTopicFile/${id}`);
	}
	static setTopicOrder(dropId, dragId) {
		return api.put(`/course/topicchangeorder/${dropId}/${dragId}`);
	}
	static setTopicOrderByOrgUser(dropId, dragId) {
		return api.put(`/B2BCourse/ChangeTopicOrder/${dropId}/${dragId}`);
	}
	static getCurrentTopicFiles(topicId) {
		return api.get(`/courseTopicFile/topicid/${topicId}/false`);
	}
	static getCurrentTopicQuizzes(topicId) {
		return api.get(`/coursequiz?entitytype=1&entityId=${topicId}`);
	}

	// File library
	static getLibraryFiles(pageNumber, pageSize, fileName) {
		if (fileName) {
			return api.get(
				`/filelibrary/pagination?pageNumber=${pageNumber}&pageSize=${pageSize}&$filename=${
					fileName ? fileName : null
				}`,
			);
		} else {
			return api.get(
				`/filelibrary/pagination?pageNumber=${pageNumber}&pageSize=${pageSize}`,
			);
		}
	}
	static getLibraryFileById(id) {
		return api.get(`/filelibrary/${id}`);
	}
	static libraryFileDelete(id) {
		return api.delete(`/filelibrary/${id}`);
	}
	static libraryFileCreate(data, subPath = "") {
		return api.post(subPath ? `/filelibrary/${subPath}` : `/filelibrary`, data);
	}
	static libraryFileCreateFromCourse(data) {
		return api.post(`/filelibrary/course`, data);
	}
	static libraryFileUpdate(data) {
		return api.put(`/filelibrary`, data);
	}
	static getFileLibrary() {
		return api.get(`/filelibrary/currentstudent`);
	}
	static getFileLibraryByTopicId(id, isAllFiles) {
		return api.get(`/courseTopicFile/topicid/${id}/${isAllFiles}`);
	}
	static getFileLibraryByTopicIdFileTypeAndCategoryId(
		topicId,
		categoryId,
		fileType,
		isAllFiles,
	) {
		return api.get(
			`/courseTopicFile/bytopic/${fileType}/${topicId}/${categoryId}/${isAllFiles}`,
		);
	}

	static getFileLibraryByFileTypeAndCategoryId(categoryId, fileType) {
		return api.get(`/filelibrary/${fileType}/${categoryId}`);
	}

	static getFileLibraryByCategoryId(categoryId) {
		return api.get(`/filelibrary/bycategory/${categoryId}`);
	}

	// Categories
	static getCategories() {
		return api.get(`/category`);
	}
	static getMainCategories(categoryType) {
		return api.get(`/category/mains/${categoryType}`);
	}
	static getCategoriesByType(type) {
		if (+type === 2) {
			return api.get(`/category/course`);
		} else {
			return api.get(`/category/bytype/${type}`);
		}
	}
	static getCategoryById(id) {
		return api.get(`/category?id=${id}`);
	}
	static categoryCreate(data) {
		return api.post(`/category/filelibfolder`, data);
	}
	static categoryUpdate(data) {
		return api.put(`/category/filelibfolder`, data);
	}
	static categoryDelete(id) {
		return api.delete(`/category/filelibfolder/${id}`);
	}
	static getThemeById(id) {
		return api.get(`/theme/${id}`);
	}
	static removeTheme(id) {
		return api.delete(`/theme/${id}`);
	}
	static createCategory(data) {
		return api.post(`/category`, data);
	}
	static createTheme(data) {
		return api.post(`/theme`, data);
	}
	static updateTheme(data) {
		return api.put(`/theme`, data);
	}
	static getThemesByMainCategoryId(mainCategoryId) {
		return api.get(`/theme/bymain/${mainCategoryId}`);
	}
	static getSubCategories(mainCategoryId) {
		return api.get(`/category/children/${mainCategoryId}`);
	}

	// Questions
	static getQuestionsByQuestionGroupId(id) {
		return api.get(
			`/question?isocode=${localStorage.getItem(
				"language",
			)}&questionsGroupId=${id}`,
		);
	}
	static getQuestionById(id) {
		return api.get(`/question/${id}`);
	}
	static questionDelete(id) {
		return api.delete(`/question/${id}`);
	}
	static questionCreate(data) {
		return api.post(`/question`, data);
	}
	static questionUpdate(data) {
		return api.put(`/question`, data);
	}

	// Questions groups
	static getQuestionsGroups() {
		return api.get(`/questionsGroup`);
	}
	static getQuestionsGroupById(id) {
		return api.get(`/questionsGroup/${id}`);
	}
	static getQuizQuestionsGroup(quizId, groupId) {
		return api.get(
			`quizquestion/byquestionsgroup?$id=${quizId}&questionsGroupId=${groupId}`,
		);
	}
	static questionsGroupDelete(id) {
		return api.delete(`/questionsGroup/${id}`);
	}
	static questionsGroupCreate(data) {
		return api.post(`/questionsGroup`, data);
	}
	static questionsGroupUpdate(data) {
		return api.put(`/questionsGroup`, data);
	}

	// Quizzes
	static quizCreate(data) {
		return api.post(`/coursequiz`, data);
	}
	static quizUpdate(data) {
		return api.put(`/coursequiz`, data);
	}
	static getQuizById(quizId) {
		return api.get(`/coursequiz/${quizId}`);
	}
	static getQuizByIdByOrgStudent(quizId) {
		return api.get(`/coursequiz/student/${quizId}`);
	}
	static quizDelete(quizId) {
		return api.delete(`/coursequiz/${quizId}`);
	}
	static getQuizzesByCourseId(id) {
		return api.get(`/coursequiz?entitytype=0&entityId=${id}`);
	}

	static getQuizzesByCourseIdByOrgStudent(id) {
		return api.get(`/coursequiz/student?entitytype=0&entityId=${id}`);
	}
	static getQuizzesByTopicId(id) {
		return api.get(`/coursequiz?entitytype=1&entityId=${id}`);
	}

	// Quiz question
	static createQuizQuestion(data) {
		return api.post(`/quizQuestion/bulkSave`, data);
	}

	// Quiz attempts
	static createQuizAttempt(id) {
		return api.post(`/quizattempt/${id}`);
	}
	static getOnGoingQuizAttempt(id) {
		return api.get(`/quizattempt/ongoing/${id}`);
	}
	static getReviewQuizAttempt(id) {
		return api.get(`/quizattempt/review/${id}`);
	}
	static getQuizAttempts(id) {
		return api.get(`/quizattempt/${id}`);
	}
	static checkQuizQuestion(data) {
		return api.post(`/quizattempt/question`, data);
	}
	static submitQuizQuestion(id, data) {
		return api.post(`/quizattempt/submit/${id}`, data);
	}
	static submitQuizQuestionLeave(id, data) {
		return api.post(`/quizattempt/submitquestions/${id}`, data);
	}
	static finishAttempt(id) {
		return api.get(`/quizattempt/finish/${id}`);
	}

	// Library
	static getReviewOptionsCategoryItems() {
		return api.get(`/library/byname/quizreviewoptions`);
	}
	static getLibraryData() {
		return api.get(`/library/byname/${VIDEO_PROFESSIONALISM_PATH_KEY}`);
	}
	static getCourseTicketSubjects() {
		return api.get(`/library/byname/course_ticket_subject`);
	}
	static getConsultationTicketSubjects() {
		return api.get(`/library/byname/consultation_ticket_subject`);
	}
	static getComplaintData(entityType, id) {
		return api.get(`/complaint/current/${entityType}/${id}`);
	}

	// Instructor
	static becomeInstructor(data) {
		return api.post(`/instructor`, data);
	}
	static getCurrentInstructorData() {
		return api.get(`/instructor/current`);
	}
	static getInstructorData(instructorId) {
		return api.get(`instructor/${instructorId}`);
	}
	static getInstructorOrConsultantData(id) {
		return api.get(`/user/profile/${id}`);
	}
	static getInstructorCourses(instructorId) {
		return api.get(`/course/instructorcourses/${instructorId}`);
	}
	static updateInstructor(data) {
		return api.put(`/instructor`, data);
	}

	// Join meeting
	static joinMeeting(data) {
		return api.post(`/user/tempRegistration`, data);
	}

	// Page content get
	static getPagecontent(id) {
		return api.get(`/pagecontent/${id}`);
	}

	static getHomePagecontent() {
		return api.get(`/page/homepage`);
	}

	// Pass-Course
	static getStudentCourseByStudentCourseId(studentCourseId) {
		return api.get(`/studentcourse/startcontinuecourse/${studentCourseId}`);
	}
	static getStudentCourseAndTopicData(studentCourseId, topicId) {
		return api.get(
			`/studentcourse/gettopicandcourse/${studentCourseId}/${topicId}`,
		);
	}
	static getStudentCourseAndTopicDataByOrgStudent(studentCourseId, topicId) {
		return api.get(
			`/studentcourse/organization/gettopicandcourse/${studentCourseId}/${topicId}`,
		);
	}
	static getTopicData(studentCourseId, topicId) {
		return api.get(`/studentcourse/getTopic/${studentCourseId}/${topicId}`);
	}
	static getStudentCourse(id) {
		return api.get(`/studentcourse/startcontinuecourse/${id}`);
	}

	//Notification
	static getNotifications() {
		return api.get(`/notification`);
	}
	static getAllNotifications(pageNumber, pageSize) {
		return api.get(
			`/notification/all?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}
	static markAsReadNotification(notificationIds) {
		return api.put(`/notification/setasread`, notificationIds);
	}

	// Video Timestump
	static updateVideoTimestump(studentCourseId, topicId, videoTimestamp) {
		return api.put(
			`/studentcourse/updatetimestamp?studentCourseId=${studentCourseId}&id=${topicId}&videoTimestamp=${videoTimestamp}`,
		);
	}

	//Currency
	static getCurrency() {
		return api.get(`/currency`);
	}

	static getCurrencyById(currencyId) {
		return api.get(`/currency/${currencyId}`);
	}

	static deleteCurrency(currencyId) {
		return api.delete(`/currency/${currencyId}`);
	}

	static createCurrency(data) {
		return api.post(`/currency`, data);
	}

	static upDateCurrency(data) {
		return api.put(`/currency`, data);
	}

	static changeCurrency(id) {
		return api.put(`/balance/${id}`);
	}

	//Shopping Cart and Wish list
	static getWishListCourses() {
		return api.get(
			`/course/wishlist/${
				localStorage.getItem("currency")
					? localStorage.getItem("currency")
					: "USD"
			}`,
		);
	}
	static getWishListCoursesForUnauthUsers(data) {
		return api.post(
			`/course/unauthorized/wishlist/${
				localStorage.getItem("currency")
					? localStorage.getItem("currency")
					: "USD"
			}`,
			data,
		);
	}
	static getShoppingCartCoursesForUnauthUsers(data) {
		return api.post(
			`/course/unauthorized/shoppingcart/${
				localStorage.getItem("currency")
					? localStorage.getItem("currency")
					: "USD"
			}`,
			data,
		);
	}
	static getShoppingCartCourses() {
		return api.get(
			`/course/shoppingcart/${
				localStorage.getItem("currency")
					? localStorage.getItem("currency")
					: "USD"
			}`,
		);
	}
	static moveCourseToWishListOrCart(courseId, isShoppingCart) {
		return api.put(`/wishlistorcart`, { courseId, isShoppingCart });
	}
	static addCourseToWishListOrCart(courseId, isShoppingCart, buyNow) {
		return api.post(`/wishlistorcart`, {
			courseId,
			isShoppingCart,
			buyNow,
		});
	}
	static wishListOrCartBulkcreate(data) {
		return api.post(`/wishlistorcart/bulkcreate `, data);
	}
	static removeCourseFromWishList(courseId) {
		return api.delete(`/wishlistorcart/wishlist/${courseId}`);
	}
	static removeCourseFromShoppingCart(courseId) {
		return api.delete(`/wishlistorcart/shoppingcart/${courseId}`);
	}
	static removeAllCoursesFromShoppingCart(courseIds) {
		return api.post(`/wishlistorcart/removefromshoppingcart`, courseIds);
	}
	static checkPrices(totalPrice, currencyId) {
		return api.get(
			`/course/checkprice?totalPrice=${totalPrice}&currencyid=${currencyId}`,
		);
	}
	static checkPriceBuyNow(totalPrice, courseId) {
		return api.get(
			`/course/checkpricebuynow?totalPrice=${totalPrice}&courseId${courseId}`,
		);
	}

	//Consultation
	static getConsultationHours() {
		return api.get(`/library/byname/consultation_hours`);
	}
	static createConsultant(data) {
		return api.post(`/consultant`, data);
	}
	static updateConsultant(data) {
		return api.put(`/consultant`, data);
	}
	static filterConsultants(data) {
		return api.post(
			`/consultant/filter/${
				localStorage.getItem("currency")
					? localStorage.getItem("currency")
					: "USD"
			}`,
			data,
		);
	}
	static createConsultation(data) {
		return api.post(`/consultation`, data);
	}
	static updateConsultation(data) {
		return api.put(`/consultation`, data);
	}
	static getPotentialConsultations(pageNumber, pageSize) {
		return api.get(
			`/consultation/potentialconsultations/${
				localStorage.getItem("currency")
					? localStorage.getItem("currency")
					: "USD"
			}/?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}
	static getPotentialconsultationsCount() {
		return api.get(
			`/consultation/potentialconsultationsCount/${
				localStorage.getItem("currency")
					? localStorage.getItem("currency")
					: "USD"
			}`,
		);
	}
	static getConsultations() {
		return api.get(`/consultation`);
	}
	static getConsultantData() {
		return api.get(`/consultant/current`);
	}
	static getAvailableHours() {
		return api.get(`/library/byname/available_hours`);
	}
	static getExperienceLevels() {
		return api.get(`/library/byname/experience_level`);
	}
	static getConsultantContracts() {
		return api.get(
			`/consultation/consultantcontracts/${
				localStorage.getItem(CURRENCY_KEY)
					? localStorage.getItem(CURRENCY_KEY)
					: DEFAULT_CURRENCY_KEY
			}`,
		);
	}
	static getStudentContracts() {
		return api.get(
			`/consultation/studentcontracts/${
				localStorage.getItem(CURRENCY_KEY)
					? localStorage.getItem(CURRENCY_KEY)
					: DEFAULT_CURRENCY_KEY
			}`,
		);
	}
	static acceptContractConsultant(consultationId, coverLetter) {
		return api.put(`/consultation/acceptcontractconsultant`, {
			consultationId,
			coverLetter,
		});
	}
	static acceptContractStudent(consultationId, consultantId) {
		return api.put(`/consultation/acceptcontractstudent`, {
			consultationId,
			consultantId,
		});
	}
	static declineContractConsultant(id) {
		return api.put(`/consultation/declinecontractconsultant/${id}`);
	}
	static declineContractStudent(id) {
		return api.put(`/consultation/declinecontractstudent/${id}`);
	}
	static applyConsultation(consultationId, coverLetter) {
		return api.put(`/consultation/applyforconsultation`, {
			consultationId,
			coverLetter,
		});
	}
	static getContractByIdForConsultant(id) {
		return api.get(
			`/consultation/contractbyconsultant/${
				localStorage.getItem(CURRENCY_KEY)
					? localStorage.getItem(CURRENCY_KEY)
					: DEFAULT_CURRENCY_KEY
			}/${id}`,
		);
	}
	static getContractByIdForStudent(id) {
		return api.get(
			`/consultation/contractbystudent/${
				localStorage.getItem(CURRENCY_KEY)
					? localStorage.getItem(CURRENCY_KEY)
					: DEFAULT_CURRENCY_KEY
			}/${id}`,
		);
	}
	static updateDateByStudent(id, date) {
		return api.put(`/consultation/updatedatebystudent`, {
			id,
			date,
		});
	}
	static updateDateByConsultant(id, date) {
		return api.put(`/consultation/updatedatebyconsultant`, {
			id,
			date,
		});
	}
	static signContract(id, offset) {
		return api.put(`/consultation/signcontract/${id}/${offset}`);
	}
	static getContractIdByWebinarId(id) {
		return api.get(`/consultation/contractid/${id}`);
	}
	static getConsultationById(consultationId) {
		return api.get(
			`/consultation/${consultationId}/${
				localStorage.getItem(CURRENCY_KEY)
					? localStorage.getItem(CURRENCY_KEY)
					: DEFAULT_CURRENCY_KEY
			}`,
		);
	}
	static removeConsultation(id) {
		return api.delete(`/consultation/${id}`);
	}
	static getConsultationSettings() {
		return api.get(`/consultation/settings`);
	}

	//Messages
	static getUnreadMessagesData() {
		return api.get(`/allMessages`);
	}
	static getAllMessagesData() {
		return api.get(`/allMessages/seeall`);
	}
	static getAllMessagesByOrgUser() {
		return api.get(`/message/availableusers`);
	}
	static getChat(id) {
		return api.get(`/message/onlybyconcretesender?senderid=${id}`);
	}
	static getTicketChatFromAdmin(chatType, ticketId, studentUserId) {
		return api.get(`/message/${chatType}/${ticketId}/${studentUserId}`);
	}
	static getTicketChatFromStudent(chatType, ticketId) {
		return api.get(`/message/${chatType}/${ticketId}`);
	}
	static getConsultationChatFromConsultant(chatType, contratId, studentUserId) {
		return api.get(`/message/${chatType}/${contratId}/${studentUserId}`);
	}
	static getConsultationChatFromStudent(chatType, contratId) {
		return api.get(`/message/${chatType}/${contratId}`);
	}
	static sendMessage(data) {
		return api.post(`/message`, data);
	}
	static sendMessageFromStudent(data) {
		return api.post(`/message/bytype`, data);
	}
	static sendMessageFromConsultantOrAdmin(data) {
		return api.post(`/message/bytypeadminconsultant`, data);
	}

	//Webinars
	static createOneTimeWebinar(data) {
		return api.post(`/webinar/onetime`, data);
	}
	static updateOneTimeWebinar(data) {
		return api.put(`/webinar/onetime`, data);
	}
	static deleteOneTimeWebinar(webinarCalendarId) {
		return api.delete(`/webinar/onetimewebinarCalendar/${webinarCalendarId}`);
	}
	static checkOneTimeWebinarDate(id) {
		return api.get(`/webinar/onetime/${id}`);
	}
	static getCurrentUserWebinars(pageNumber, pageSize) {
		return api.get(
			`/webinar/onetime?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}
	static joinToWebinar(webinarCalendarId, firstname) {
		return api.post(`/user/onetimewebinaruser`, {
			webinarCalendarId,
			firstname,
		});
	}
	static getWebinarsByOrgUser(pageNumber, pageSize, user) {
		if (user.userTypeId === STUDENT_USER_TYPE) {
			return api.get(
				`/b2BWebinar/GetByPaginationForStudent?pageNumber=${pageNumber}&pageSize=${pageSize}`,
			);
		} else if (
			user.userTypeId === LECTURER_USER_TYPE ||
			(user.defaultUserTypeIds &&
				user.defaultUserTypeIds.lebgth &&
				user.defaultUserTypeIds.includes(LECTURER_USER_TYPE))
		) {
			return api.get(
				`/b2BWebinar/GetByPaginationForLecturer?pageNumber=${pageNumber}&pageSize=${pageSize}`,
			);
		} else if (
			user.userTypeId === CURATOR_USER_TYPE ||
			(user.defaultUserTypeIds &&
				user.defaultUserTypeIds.lebgth &&
				user.defaultUserTypeIds.includes(CURATOR_USER_TYPE))
		) {
			return api.get(
				`/b2BWebinar/GetByPaginationForCurator?pageNumber=${pageNumber}&pageSize=${pageSize}`,
			);
		}
		return api.get(
			`/b2BWebinar/GetByPaginationForStudent?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}
	static getOnGoingWebinars(pageNumber, pageSize) {
		return api.get(
			`/b2BWebinar/GetOngoingWebinars?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}
	static getWebinarbyIdFromOrgUser(webinarId, user) {
		if (user.userTypeId === STUDENT_USER_TYPE) {
			return api.get(`/b2bwebinar/GetByStudent/${webinarId}`);
		} else if (
			user.userTypeId === LECTURER_USER_TYPE ||
			(user.defaultUserTypeIds &&
				user.defaultUserTypeIds.lebgth &&
				user.defaultUserTypeIds.includes(LECTURER_USER_TYPE))
		) {
			return api.get(`/b2bwebinar/GetByLecturer/${webinarId}`);
		} else if (
			user.userTypeId === CURATOR_USER_TYPE ||
			(user.defaultUserTypeIds &&
				user.defaultUserTypeIds.lebgth &&
				user.defaultUserTypeIds.includes(CURATOR_USER_TYPE))
		) {
			return api.get(`/b2bwebinar/GetForCurator/${webinarId}`);
		}
		return api.get(`/b2bwebinar/GetForCurator/${webinarId}`);
	}
	static getInProcessWebinar() {
		return api.get(`/webinar/ongoingonetime`);
	}
	static finishWebinar(id) {
		return api.put(`/webinar/closeWebinar/${id}`);
	}

	//Payment
	static getBalance() {
		return api.get(
			`/balance/byisocode/${
				localStorage.getItem(CURRENCY_KEY)
					? localStorage.getItem(CURRENCY_KEY)
					: DEFAULT_CURRENCY_KEY
			}`,
		);
	}
	static getBalanceAsOrgUser() {
		return api.get(`/balance/organization`);
	}

	static getPaymentHistory() {
		return api.get(`/payment`);
	}
	static getPaymentHistoryWithPagination(
		paymentType,
		entityType = 0,
		pageNumber,
		pageSize = 10,
	) {
		return api.get(
			`/payment/history/${paymentType}/${entityType}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}
	static getPaymentSystems() {
		return api.get(`/paymentSystem`);
	}
	static paymentFillPayPal(data) {
		return api.post(`/paypal`, data);
	}
	static paymentFillStripe(data) {
		return api.post(`/stripe`, data);
	}
	static paymentFillCryllex(data) {
		return api.post(`/cryllex`, data);
	}
	static sendStripeResponse(data) {
		return api.post(`/stripe/chekoutResponse`, data);
	}
	static paymentFill(data) {
		return api.post(`/payment`, data);
	}
	static paymentAndPurchasePaypalCourse(data) {
		return api.post(`/paypal/coursepurchase`, data);
	}
	static coursePurchase(data) {
		return api.post(`/payment/coursepurchase`, data);
	}
	static paymentAndPurchaseStripeCourse(data) {
		return api.post(`/stripe/coursepurchase`, data);
	}
	static paymentAndPurchasePaypalPackage(data) {
		return api.post(`/paypal/packagepurchase`, data);
	}
	static paymentAndPurchaseStripePackage(data) {
		return api.post(`/payment/packagepurchase`, data);
	}
	static paymentAndPurchasePackage(data) {
		return api.post(`/payment/packagepurchase`, data);
	}
	static paymentAndPurchaseBankTransaction(data) {
		return api.post(`/organizationPaymentPackage/selectpackage`, data);
	}
	static payOut(data) {
		return api.post(`/payment/payout`, data);
	}
	static payPalPayOut(data) {
		return api.post(`/paypal/payout`, data);
	}
	static stripePayOut(data) {
		return api.post(`/stripe/payout`, data);
	}
	static paymentPayOut(data) {
		return api.post(`/payment/payout`, data);
	}
	static getPayPalResponse(status, url) {
		return api.get(`/paypal/${status}/${url}`);
	}
	static getCryllexResponse(status, url) {
		return api.get(`/cryllex/${status}/${url}`);
	}
	static getStripeResponse(status, id) {
		return api.get(`/stripe/${status}?paymentId=${id}`);
	}
	static successAndCheckoutPayPal(data) {
		return api.post(`/paypal/successandpurchasecourses`, data);
	}
	static successAndCheckoutPayPalPackage(data) {
		return api.post(`/paypal/successandpurchasepackage`, data);
	}
	static checkStudentBalance(id) {
		return api.get(`/consultation/checkbalance/${id}`);
	}

	//Feedback
	static sendFeedBackFromStudent(data) {
		return api.post(`/review`, data);
	}
	static updateFeedBackFromStudent(data) {
		return api.put(`/review`, data);
	}
	static sendFeedBackFromConsultant(data) {
		return api.put(`/review/reply`, data);
	}
	static getFeedBackDataForStudent(entitytype, entityid) {
		return api.get(`/review/current/${entitytype}/${entityid}`);
	}
	static getFeedBackDataForConsultant(entitytype, contractId) {
		return api.get(`/review/currentbyConsultant/${entitytype}/${contractId}`);
	}
	static getFeedbacksByEntitytype(entitytype, pageNumber, pageSize) {
		return api.get(
			`/review/notapproved/${entitytype}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}
	static approveFeedback(id) {
		return api.put(`/review/approve/${id}`);
	}
	static declineFeedback(data) {
		return api.put(`/review/decline`, data);
	}

	//complaint
	static makeAcomplaint(data) {
		return api.post(`/complaint`, data);
	}

	//Tickets
	static getTicketsData() {
		return api.get(`/complaint/current`);
	}
	static getInprocessTickets(pageNumber, pageSize) {
		return api.get(
			`/complaint/admin/inprocess?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}
	static getResolvedTickets(pageNumber, pageSize) {
		return api.get(
			`/complaint/admin/resolved?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}
	static getTicketByIdFromAdmin(ticketId) {
		return api.get(`/complaint/admin/${ticketId}`);
	}
	static getTicketByIdFromStudent(ticketId) {
		return api.get(`/complaint/student/${ticketId}`);
	}
	static refundTicket(ticketId) {
		return api.put(`/complaint/admin/refund/${ticketId}`);
	}
	static rejectTicket(ticketId) {
		return api.put(`/complaint/admin/declinerefund/${ticketId}`);
	}
	static resolveTicket(ticketId) {
		return api.put(`/complaint/admin/resolve/${ticketId}`);
	}

	//Search
	static globalSearch(text) {
		return api.get(`/search?keyword=${text}`);
	}

	//Specialty
	static getSpecialtyKeywords(specialty) {
		return api.get(`/librarydata/specialty?keyword=${specialty}`);
	}

	//key words admin
	static getSpecialityKeyWords(pageNumber, pageSize) {
		return api.get(
			`/librarydata/specialties?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}
	static getSearchKeyWords(pageNumber, pageSize) {
		return api.get(
			`/search/unconfirmed?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}
	static confirmSpecialityKeyWord(id) {
		return api.put(`/librarydata/specialty/${id}`);
	}
	static declineSpecialityKeyWord(id) {
		return api.delete(`/librarydata/specialty/${id}`);
	}
	static confirmSearchKeyWord(id) {
		return api.put(`/search/confirm/${id}`);
	}
	static declineSearchKeyWord(id) {
		return api.delete(`/search/${id}`);
	}
	static buldRemoveSpecialities(data) {
		return api.post(`/librarydata/specialty/bulkdelete`, data);
	}
	static buldRemoveKeyWords(data) {
		return api.post(`/search/bulkdelete`, data);
	}

	//Info pages
	static getMindalayForBusinessInfoPageContent() {
		return api.get(`/page/byname/TR_MINDALAY_FOR_BUSINESS_INFO_PAGE`);
	}
	static getLearnInMindalayInfoPageContent() {
		return api.get(`/page/byname/TR_LEARN_IN_MINDALAY_INFO_PAGE`);
	}
	static getTeachInMindalayInfoPageContent() {
		return api.get(`/page/byname/TR_TEACH_IN_MINDALAY_INFO_PAGE`);
	}
	static getBecomeConsultantInfoPageContent() {
		return api.get(`/page/byname/TR_BECOME_A_CONSULTANT_INFO_PAGE`);
	}

	//Organization pages
	static getPagesFromOrgUser() {
		return api.get(`/page/struct?frontendpage=false`);
	}
	static getPagesFromOrgUserByUserTypeId(userTypeId) {
		return api.get(
			`/page/struct?frontendpage=false&defaultUserTypeId=${userTypeId}`,
		);
	}
	static getPrograms() {
		return api.get(`/organizationEducationLevel/programnames`);
	}
	static getSubjectsByProgramId(programId) {
		// return api.get(`/${ORGANIZATION_EDUCATION_LEVEL_KEY}/${programId}`);
		return api.get(
			`/organizationEducationLevel/subjectbyprogramname/${programId}`,
		);
	}
	static getStudentsByLecturer(pageNumber = null, pageSize) {
		if (pageNumber) {
			return api.get(
				`/studentcourse/lecturer/allstudents?pageNumber=${pageNumber}&pageSize=${pageSize}`,
			);
		}
		return api.get(`/studentcourse/lecturer/allstudents`);
	}
	static getStudentsByCurator(pageNumber = null, pageSize) {
		if (pageNumber) {
			return api.get(
				`/Division/GetAllStudentsOfCurator?pageNumber=${pageNumber}&pageSize=${pageSize}`,
			);
		}
		return api.get(`/Division/GetAllStudentsOfCurator`);
	}
	static setStudentCourses(id) {
		return api.get(`/B2BCourse/GetAllCoursesAsStudent/${id}`);
	}
	static onPauseCourseForStudent(data) {
		return api.put(`/studentcourse/pausecourse`, data);
	}
	static onBlockCourseForStudent(data) {
		return api.put(`/studentcourse/blockcourse`, data);
	}
	static getGroupData(pageNumber, pageSize) {
		return api.get(
			`/group/pagination?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}
	static getGroupStudents(groupId) {
		return api.get(`/groupstudent/groupId/${groupId}/${true}`);
	}
	static changeStudentAttachment(data) {
		return api.post(`/groupstudent`, data);
	}
	static getCurators() {
		return api.get(
			`/user?usertypeId=${CURATOR_USER_TYPE}&pageNumber=1&pageSize=1000`,
		);
	}
	static getOrganizationEducationalLevels() {
		return api.get(`/organizationEducationLevel`);
	}
	static getOrganizationEducationalLevelsById(id) {
		return api.get(`/organizationEducationLevel/${id}`);
	}
	static createGroup(data) {
		return api.post(`/group`, data);
	}
	static updateGroup(data) {
		return api.put(`/group`, data);
	}
	static getGroupById(id) {
		return api.get(`/group/${id}`);
	}
	static getWebinarsByCurator(pageNumber, pageSize) {
		return api.get(
			`/b2BWebinar/GetByPagination?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}
	static getGroups() {
		return api.get(`/group`);
	}
	static getLecturersByDisciplineId(disciplineId) {
		return api.get(`/subjectLecturer/subjectId/${disciplineId}/${false}`);
	}
	static createOrgWebinar(data) {
		return api.post(`B2BWebinar/create`, data);
	}
	static updateOrgWebinar(data) {
		return api.put(`B2BWebinar/Update`, data);
	}
	static updateWebinarCalendar(data) {
		return api.put(`/b2BWebinar/UpdateWebinarCalendar`, data);
	}
	static removeCalendarFromCurrentWebinarDay(id) {
		return api.delete(`/b2BWebinar/DeleteWebinarCalendar/${id}`);
	}
	static getStudentsByOrganizationId(id) {
		return api.get(`/groupstudent/groupId/${id}/${false}`);
	}
	static getStudentDataById(id) {
		return api.get(`/student/detailswithdocs/${id}`);
	}
	static getHelpPageContent() {
		return api.get(`/page/byname/TR_HELP_PAGE`);
	}
	static getHelpPageContentBySpecialDomain() {
		return api.get(`/page/B2BHelp`);
	}
	static getPageById(id) {
		return api.get(`/page/byId/${id}`);
	}
	static updateOrgUserPassword(data) {
		return api.put(`/user/updatepassword`, data);
	}
	static updateOrgUserAvatar(data) {
		return api.put(`/user`, data);
	}
	static createOrganization(data) {
		return api.post(`/organization/autoregistration`, data);
	}
	static getPaymentPackages() {
		return api.get(
			`/paymentPackage/forsale/${
				localStorage.getItem(CURRENCY_KEY)
					? localStorage.getItem(CURRENCY_KEY)
					: DEFAULT_CURRENCY_KEY
			}`,
		);
	}
	static updatePaymentPackage(id) {
		return api.put(`/organizationPaymentPackage/recurring/${id}`);
	}
	static getAllPaymentPackages() {
		return api.get(
			`/paymentPackage/promo/${
				localStorage.getItem(CURRENCY_KEY)
					? localStorage.getItem(CURRENCY_KEY)
					: DEFAULT_CURRENCY_KEY
			}`,
		);
	}
	static getPaymentPackageItems(id) {
		return api.get(
			`/paymentPackage/withprices/${id}/${
				localStorage.getItem(CURRENCY_KEY)
					? localStorage.getItem(CURRENCY_KEY)
					: DEFAULT_CURRENCY_KEY
			}`,
		);
	}
	static makeAdepositPaymentPackage(data) {
		return api.post(`/organizationPaymentPackage/save`, data);
	}
	static getPaymentPackageHistory() {
		return api.get(
			`/organizationPaymentPackage/current/${
				localStorage.getItem(CURRENCY_KEY)
					? localStorage.getItem(CURRENCY_KEY)
					: DEFAULT_CURRENCY_KEY
			}`,
		);
	}
	static deleteKuratorWebinar(id) {
		return api.delete(`/b2BWebinar/delete/${id}`);
	}
	static getPaymentLogs(pageNumber, pageSize) {
		return api.get(
			`/systemlog/localpayments?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}
	static getBalanceLogs(pageNumber, pageSize) {
		return api.get(
			`/systemlog/balance?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}
	static getLogByPaymentId(id) {
		return api.get(`/payment/${id}`);
	}
	static contactUs(data) {
		return api.post(`/contactus`, data);
	}
	static getOnetimeWebinarPageContent() {
		return api.get(`/page/byname/TR_CONFERENCE_ROOM_PAGE`);
	}
	static sendPaymentReceipt(data) {
		return api.post(`/organizationPayments`, data);
	}
	static updatePaymentReceipt(data) {
		return api.put(`/organizationPayments`, data);
	}
	static getPaymentReceipts(receiptType, pageNumber, pageSize) {
		return api.get(
			`/organizationPayments/${
				localStorage.getItem(CURRENCY_KEY)
					? localStorage.getItem(CURRENCY_KEY)
					: DEFAULT_CURRENCY_KEY
			}/${receiptType}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}
	static acceptPaymentReceipt(id) {
		return api.put(`/organizationPaymentPackage/confirmpayment/${id}`);
	}
	static declinePaymentReceipt(data) {
		return api.put(`/organizationPayments/reject`, data);
	}
	static activatePackage(id) {
		return api.put(`/organizationPaymentPackage/activatepackage/${id}`);
	}
	static getWebinarData(id) {
		return api.get(`/webinar/webinarCalendarResources/${id}`);
	}
	static getWebinarVideos(url, webinarCalendarId, userId) {
		return axios({
			method: "get",
			url: `${url}WebinarVideo/get/${webinarCalendarId}/${userId}`,
			headers: {
				Authorization: localStorage.getItem(TOKEN_KEY),
				language: localStorage.getItem(LANGUAGE_KEY),
			},
		});
	}
	static getFileLibraryAndStudents() {
		return api.get(`/filelibrary/filesandstudents`);
	}
	static getStudentsByFileIds(data) {
		return api.post(`/filelibrary/studentsbyfiles`, data);
	}
	static getFilesbyStudents(data) {
		return api.post(`/filelibrary/filesbystudents`, data);
	}
	static getFilesToStudents(data) {
		return api.post(`/filelibrary/attachfiletostudents`, data);
	}
	static getOrgAdminInfo() {
		return api.get(`/organizationPaymentPackage/datainfo`);
	}
	static getQuizResults(courseId, studentId) {
		return api.get(
			`/quizattempt/organization/quizinfo/${courseId}/${studentId}`,
		);
	}
	static getHelpData(key) {
		return api.get(`/librarydata/howto?pagename=${key}`);
	}
	static getCurrentQuestionMarkData(pageName, key) {
		return api.get(
			`/librarydata/howto/single?pagename=${pageName}&dataname=${key}`,
		);
	}
	static getPaymentHistoryForMindalayAdmin(pageNumber, pageSize, entityType) {
		return api.get(
			`/payment/mindalayhistory/${entityType}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}
	static getPaymentPackageById(paymentPackageEntityId) {
		return api.get(
			`/organizationPayments/byId/${paymentPackageEntityId}/${
				localStorage.getItem(CURRENCY_KEY)
					? localStorage.getItem(CURRENCY_KEY)
					: DEFAULT_CURRENCY_KEY
			}`,
		);
	}
	static getSystemTranslations(pageNumber, pageSize, isocode, type) {
		return api.get(
			`/translation/pagination?pageNumber=${pageNumber}&pageSize=${pageSize}&isocode=${isocode}`,
		);
	}
	static getOrganizationTranslations(pageNumber, pageSize, isocode, type) {
		return api.get(
			`/translation/organization?pageNumber=${pageNumber}&pageSize=${pageSize}&isocode=${isocode}`,
		);
	}

	static getOrganizationTranslationsByOrganizationId(
		pageNumber,
		pageSize,
		isocode,
		type,
	) {
		if (!type) {
			return api.get(
				`translation/organization?pageNumber=${pageNumber}&pageSize=${pageSize}&isocode=${isocode}`,
			);
		} else {
			return api.get(
				`translation/organization?pageNumber=${pageNumber}&pageSize=${pageSize}&isocode=${isocode}&type=${type}`,
			);
		}
	}

	static deleteSystemTranslation(id) {
		return api.delete(`/translation/${id}`);
	}

	// static createSystemTranslation(data) {
	// 	return api.post(`/translation`, data);
	// }

	// static updateSystemTranslation(data) {
	// 	return api.put(`/translation`, data);
	// }

	static getSystemTranslationsByType(isocode, type) {
		return api.get(`translation?isocode=${isocode}&type=${type}`);
	}
	static getOraganizationTranslationsByType(isocode, type) {
		return api.get(`translation/organization?isocode=${isocode}&type=${type}`);
	}

	static searchTranslations(keyword, isocode, type = null) {
		return api.get(`/translation/filter?keyword=${keyword}&isocode=${isocode}`);
	}
	static createTransalation(data) {
		return api.post(`/translation`, data);
	}
	static updateTransalation(data) {
		return api.put(`/translation`, data);
	}
	static updateOrganizationTransalation(data) {
		return api.put(`/translation/organization`, data);
	}

	//Document
	static createDocument(formData) {
		return api.post(`/organizationDocument`, formData);
	}
	static updateDocument(formData) {
		return api.put(`/organizationDocument`, formData);
	}
	static getDocumentTemplateInfoData(templateInfoId) {
		return api.get(
			`/documentTemplateInfoData?templateInfoId=${templateInfoId}`,
		);
	}
	static getOrganizationDocument(categoryId) {
		return api.get(`/organizationDocument?categoryId=${categoryId}`);
	}
	static getDocumentByIdAsStudent(documentId) {
		return api.get(`/organizationDocument/studentdoc/${documentId}`);
	}
	static getDocumentByIdAsLecturer(documentId) {
		return api.get(`/organizationDocument/lecturerdoc/${documentId}`);
	}
	static getDocumentByIdAsCurator(documentId) {
		return api.get(`/organizationDocument/organizationDocument/${documentId}`);
	}
	static getStudentDocumentsByStudent() {
		return api.get(`/organizationDocument/student`);
	}
	static getStudentDocumentsByLecturer(userId) {
		return api.get(`/organizationDocument/lecturer/${userId}`);
	}
	static getStudentDocumentsByCurator(userId) {
		return api.get(`/organizationDocument/curator/${userId}`);
	}
	static deleteDocument(documentId) {
		return api.delete(`/organizationDocument/${documentId}`);
	}
	static getOrgAndGroupname() {
		return api.get(`/groupstudent/organdgroupname`);
	}
	static documentSign(formData) {
		return api.put(`/organizationDocument/sign`, formData);
	}
	static courseCopy(courseId) {
		return api.post(`/B2BCourse/Copy/${courseId}`);
	}
	static getPackageProperties() {
		return api.get(`/packageProperty`);
	}
	static calculateCustomPaymentPackagePrice(data) {
		return api.post(`/packageProperty/calculate`, data);
	}
	static createCustomPaymentPackage(data) {
		return api.post(`/packageProperty/create`, data);
	}
	static getCourseByIdFromOrgAdmin(id) {
		return api.get(`/B2BCourse/GetAsAdmin/${id}`);
	}
	static getProgramsAsAdmin() {
		return api.get(`/division/GetEducationalUnitsOfOrganization`);
	}
	static getSubjectsAsAdmin(id) {
		return api.get(
			`/organizationEducationLevel/subjectbyprogramname/asadmin/${id}`,
		);
	}
	static getCoursesBySubjectId(id) {
		return api.get(`/B2BCourse/GetAllCoursesBySubjectAsAdmin/${id}`);
	}
	static createCoursePriceAsAdmin(data) {
		return api.post(`/innerPayment`, data);
	}
	static updateCoursePriceAsAdmin(data) {
		return api.put(`/innerPayment`, data);
	}
	static getInnerPaymentAsAdmin(id) {
		return api.get(`/innerPayment/bycourse/${id}`);
	}
	static payLessThanFullPriceForCourse(innerPaymentId) {
		return api.post(`/studentInnerPayment/${innerPaymentId}`);
	}
	static payFullPriceForCourse(innerPaymentId) {
		return api.post(`/studentInnerPayment/fullpayment/${innerPaymentId}`);
	}
	static getPaymentSchedule(innerPaymentId) {
		return api.get(`/studentInnerPayment/schedule/${innerPaymentId}`);
	}
	static getPaymentScheduleForOrgAdmin(courseId, studentId) {
		return api.get(
			`/studentInnerPayment/bycourseandstudent/${courseId}/${studentId}`,
		);
	}
	static getPaymentSchedules() {
		return api.get(`/innerPayment/bystudent`);
	}
	static createDeposit(data) {
		return api.post(`/payment/deposit`, data);
	}
	static getCheckedquestions(attemptId) {
		return api.get(`/quizattempt/checkedquestions/${attemptId}`);
	}
	static getLibraryDataByPageName(pageName) {
		return api.get(`/librarydata/page_helps?pageName=${pageName}`);
	}
	static getLeaderbordInformation(type, data) {
		return api.post(`/${type}/leaderboardinfo`, data);
	}

	// Structure
	//
	//Divisions
	static getDivisions(pageNumber = 1, pageSize = 10, ownerId) {
		if (ownerId) {
			return api.get(
				`division/getOwnerDivisionsByPagination?pageNumber=${pageNumber}&pageSize=${pageSize}&ownerId=${ownerId}`,
			);
		}
		return api.get(
			`division/getOwnerDivisionsByPagination?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}
	static getAllDivisions(ownerId) {
		return api.get(`division/getOwnerDivisionsForParenting?ownerId=${ownerId}`);
	}
	static getDivisionsById(divisionId) {
		return api.get(`division/get/${divisionId}`);
	}
	static deleteDivision(divisionId) {
		return api.delete(`division/delete/${divisionId}`);
	}
	static createDivisions(data) {
		return api.post(`division/create`, data);
	}
	static updateDivision(data) {
		return api.put(`division/update`, data);
	}
	static getDivisionsStudents(id, isGrupeStudents = true) {
		return api.get(
			`divisionStudent/getStudentsByDivision/${id}/${isGrupeStudents}`,
		);
	}
	static setDivisionsStudents(data) {
		return api.post(`divisionStudent/save`, data);
	}
	static getOwnerDivisionsForWebinars(divisionId) {
		if (divisionId) {
			return api.get(
				`/division/GetOwnerDivisionsForWebinars?divisionId=${divisionId}`,
			);
		} else {
			return api.get(`/division/GetOwnerDivisionsForWebinars`);
		}
	}
	static getEducationalUnitsOfLecturer(educationalUnitId) {
		if (educationalUnitId) {
			return api.get(
				`/division/GetEducationalUnitsOfLecturer?educationalUnitId=${educationalUnitId}`,
			);
		} else {
			return api.get(`/division/GetEducationalUnitsOfLecturer`);
		}
	}
	//

	// EducationalUnits
	static getEducationalUnits(pageNumber = 1, pageSize = 10) {
		return api.get(
			`educationalUnit/getEducationalUnitsByPagination?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}
	static getAllEducationalUnits() {
		return api.get(`educationalUnit/GetEducationalUnitsForParenting`);
	}
	static getEducationalUnitsById(educationalUnitId) {
		return api.get(`educationalUnit/get/${educationalUnitId}`);
	}
	static getReplacementLecturers(lecturerUserId, subjectId) {
		return api.get(
			`educationalUnitLecturer/getReplacementLecturers/${lecturerUserId}/${subjectId}`,
		);
	}
	static getOwnerDivisions() {
		//todo
		return api.get(`division/getOwnerDivisions`);
	}
	static getDivisionById(divisionId) {
		return api.get(`division/get/${divisionId}`);
	}
	static deleteEducationalUnit(divisionId) {
		return api.delete(`educationalUnit/delete/${divisionId}`);
	}
	static createEducationalUnit(data) {
		return api.post(`/educationalUnit/Create`, data);
	}
	static updateEducationalUnit(data) {
		return api.put(`educationalUnit/update`, data);
	}
	static getEducationalDivisions(id, isActiv = true) {
		return api.get(
			`educationalUnitDivision/getDivisionsByEducationalUnit/${id}/${isActiv}`,
		);
	}
	static setEducationalDivisions(data) {
		return api.post(`educationalUnitDivision/save`, data);
	}
	static getEducationalLecturers(id, isActiv = true) {
		return api.get(
			`educationalUnitLecturer/getLecturersByEducationalUnit/${id}/${isActiv}`,
		);
	}
	static setEducationalLecturers(data) {
		return api.post(`educationalUnitLecturer/save`, data);
	}
	//
	//Settings
	//----------------------------------------------------------------------------------
	static getOrganizationSetting = shortname => {
		return api.get(`organizationSetting/${shortname}`);
	};
	static createSettings = data => {
		return api.post(`organizationSetting/createWhiteLabelSettings`, data);
	};
	static updateSettings = data => {
		return api.put(`organizationSetting/updateWhiteLabelSettings`, data);
	};
	static getCurrentOrganizationSettingsAdmin = id => {
		return api.get(
			`organizationSetting/getCurrentOrganizationSettingsAdmin/${id}`,
		);
	};
	static updateSettingsWhiteLabelMediaSettings = data => {
		return api.put(`organizationSetting/updateWhiteLabelMediaSettings`, data);
	};
	static updateSettingsWhiteLabelColors = data => {
		return api.put(`organizationSetting/UpdateWhiteLabelColorSettings`, data);
	};
	static getSettingByShortName = shortname => {
		return api.get(
			`organizationSetting/GetByOrganizationShortName/${shortname}`,
		);
	};
	static getCurrentOrganizationSettings = () => {
		return api.get(`organizationSetting/getCurrentOrganizationSettings`);
	};

	static checkWebinarDays = (url, data) => {
		return api.post(`/${url}`, data);
	};

	static getCourseStudentsByCourseId(courseId) {
		return api.get(`/studentcourse/coursestudents/${courseId}`);
	}
	static getOrganizationInnerPaymentContract() {
		return api.get(`organizationInnerPaymentContract/getbalance`);
	}
	static getProgramsOfLecturer = () => {
		return api.get(`EducationalUnit/GetProgramsOfLecturer`);
	};
	static createCourseAssignment(data) {
		return api.post(`assignment/createCourseAssignment`, data);
	}
	static deleteAssignment(id) {
		return api.delete(`assignment/deleteAssignment/${id}`);
	}
	static createTopicAssignment(data) {
		return api.post(`assignment/createTopicAssignment`, data);
	}
	static updateCourseAssignment(data) {
		return api.put(`assignment/updateCourseAssignment`, data);
	}
	static updateTopicAssignment(data) {
		return api.put(`assignment/updateTopicAssignment`, data);
	}
	static getAssignmentByLecturerId(id) {
		return api.get(`/assignment/getAssignmentByLecturer/${id}`);
	}
	static getQuizzesAssignment(id) {
		return api.get(`/assignment/GetTopicAssignment?entityId=${id}`);
	}
	static getAssignmentByCourseId(id) {
		return api.get(`/assignment/GetCourseAssignment?entityId=${id}`);
	}

	// static getQuizzesAssignment(id) {
	// 	return api.get(
	// 		`/assignment/getAssignmentByType?entitytype=1&entityId=${id}`,
	// 	);
	// }
	// static getAssignmentByCourseId(id) {
	// 	return api.get(
	// 		`/assignment/getAssignmentByType?entitytype=0&entityId=${id}`,
	// 	);
	// }
	static getStudentAssignment(id) {
		return api.get(`studentAssignment/getStudentAssignment?id=${id}`);
	}
	static getStudentAssignmentById(id) {
		return api.get(`studentAssignment/getStudentAssignmentById/${id}`);
	}
	static createStudentAssignment(data) {
		return api.post(
			`studentAssignment/createStudentAssignmentResultByStudent`,
			data,
		);
	}
	static createLecturerAssignment(data) {
		return api.post(
			`studentAssignment/createStudentAssignmentResultByLecturer`,
			data,
		);
	}
	static getLecturerAssignments(courseId) {
		return api.get(`assignment/getAssignmentsOfCourse?courseId=${courseId}`);
	}
	static GetStudentAssignmentByLecturer(id, studentId) {
		return api.get(
			`studentAssignment/getStudentAssignmentByLecturer?id=${id}&studentId=${studentId}`,
		);
	}
	static markAssignmentAsComplete(data) {
		return api.post(`studentAssignment/MarkAssignmentAsComplete`, data);
	}
	static getByDivision(divisionId) {
		return api.get(`B2BWebinar/GetByDivision?divisionId=${divisionId}`);
	}
	static getByOwner(ownerId) {
		return api.get(`B2BWebinar/GetByOwner?ownerId=${ownerId}`);
	}
	//developer
	static getUsertypes(orgId) {
		if (orgId) {
			return api.get(`usertype?orgId=${orgId}`);
		}
		return api.get(`usertype`);
	}
	static getUserTypeById(id) {
		return api.get(`usertype/${id}`);
	}
	static createUserType(data) {
		return api.post(`usertype`, data);
	}
	static updateUserType(data) {
		return api.put(`usertype`, data);
	}
	static getDefaultUserType() {
		return api.get(`usertype/default`);
	}
	static deleteUsertype(id) {
		return api.delete(`usertype/${id}`);
	}
	static getOrganizations() {
		return api.get(`organization`);
	}
	static getPageusertype(usertypeId) {
		return api.get(
			`pageusertype/page?usertypeId=${usertypeId}&frontendpage=false`,
		);
	}
	static postPageusertype(data) {
		return api.post(`pageusertype`, data);
	}
	static getUsers(pageNumber, usertypeId = null, pageSize = 10) {
		if (usertypeId) {
			return api.get(
				`user?usertypeId=${usertypeId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
			);
		}
		return api.get(`user?pageNumber=${pageNumber}&pageSize=${pageSize}`);
	}
	static getUser(userId) {
		return api.get(`user/${userId}`);
	}
	static createUser(data) {
		return api.post(`user`, data);
	}
	static updateUser(data) {
		return api.put(`user`, data);
	}
	static user_activate(userId, active) {
		return api.post(`user/activate/${userId}/${active}`);
	}
	static getTimeZone() {
		return api.get(`librarydata/time_zones`);
	}
	static filterUsers(data) {
		return api.post(`user/filter`, data);
	}
	static getStudents() {
		return api.get(`/student`);
	}
	static createStudent(data) {
		return api.post(`/student`, data);
	}
	static updateStudent(data) {
		return api.put(`/student`, data);
	}
	static studentBulkUpload(data) {
		return api.post(`student/studentbulkupload`, data);
	}

	static getStudentById(studentId) {
		return api.get(`/student/${studentId}`);
	}
	static getStudentByGrup(grupId) {
		return api.get(`groupstudent/groupId/${grupId}/false`);
	}
	static getPagerole(pageId, usertypeId) {
		return api.get(`pagerole?pageId=${pageId}&usertypeId=${usertypeId}`);
	}
	static changeUserTypeRole(data) {
		return api.post(`usertyperole`, data);
	}
	static getDeveloperPaymentPackages() {
		return api.get(`paymentpackage`);
	}
	static getDeveloperPaymentPackage(paymentId) {
		return api.get(`paymentpackage/${paymentId}`);
	}
	static createDeveloperPaymentPackage(data) {
		return api.post(`paymentpackage`, data);
	}
	static updateDeveloperPaymentPackage(data) {
		return api.put(`paymentpackage`, data);
	}
	static deletePaymentpackage(paymentId) {
		return api.delete(`paymentpackage/${paymentId}`);
	}
	static getRoles() {
		return api.get(`role`);
	}
	static createRole(data) {
		return api.post(`role`, data);
	}
	static updateRole(data) {
		return api.put(`role`, data);
	}
	static deleteRole(id) {
		return api.delete(`role/${id}`);
	}

	static getConfig() {
		return api.get(`config`);
	}
	static getConfigById(configId) {
		return api.get(`config/${configId}`);
	}
	static createConfig(data) {
		return api.post(`config`, data);
	}
	static updateConfig(data) {
		return api.put(`config`, data);
	}
	static deleteConfig(configId) {
		return api.delete(`config/${configId}`);
	}
	static getPageContentByLang(langId) {
		return api.get(`pageContent/bylang/${langId}`);
	}
	static getPageContentByLangByID(langId, pageContentId) {
		return api.get(
			`pageContent/getbylang?id=${pageContentId}&langId=${langId}`,
		);
	}
	static deletePageContent(pageContentId) {
		return api.delete(`pageContent/${pageContentId}`);
	}
	static creataPageContent(data) {
		return api.post(`pageContent`, data);
	}
	static updatePageContent(data) {
		return api.put(`pageContent`, data);
	}

	static getForcontent() {
		return api.get("page/getforcontent");
	}

	static getCategories(type = null) {
		if (type !== null) {
			return api.get(`category/bytype/${type}`);
		}
		return api.get(`category`);
	}
	static createCategory(data) {
		return api.post(`category`, data);
	}
	static updateCategory(data) {
		return api.put(`category`, data);
	}
	static deleteCategory(id) {
		return api.delete(`category/${id}`);
	}
	static getLibraryDataByIso(isoCode) {
		return api.get(`library/byiso?isocode=${isoCode}`);
	}
	static getLibraryDataByIsoAndLibId(isoCode, libId) {
		return api.get(`librarydata/byiso?libId=${libId}&isocode=${isoCode}`);
	}
	static createLibrary(data) {
		return api.post(`library`, data);
	}
	static updateLibrary(data) {
		return api.put(`library`, data);
	}
	static deleteLibrary(id) {
		return api.delete(`library/${id}`);
	}
	static createLibrarydata(data) {
		return api.post(`librarydata`, data);
	}
	static updateLibrarydata(data) {
		return api.put(`librarydata`, data);
	}
	static deleteLibrarydata(id) {
		return api.delete(`librarydata/${id}`);
	}
	static getLibrarydataByLibId(libId) {
		return api.get(`librarydata?libId=${libId}`);
	}
	static getWhitelabelLanguages() {
		return api.get(`language/whitelabel`);
	}
	static getDocumentCategory() {
		return api.get("documentCategory");
	}
	static createDocumentCategory(data) {
		return api.post("documentCategory", data);
	}
	static updateDocumentCategory(data) {
		return api.put("documentCategory", data);
	}
	static deleteDocumentCategory(documentCategoryId) {
		return api.delete(`documentCategory/${documentCategoryId}`);
	}

	static getDocumentTemplateInfoDatas() {
		return api.get(`documentTemplateInfoData`);
	}
	static getDocumentTemplateById(id = null) {
		return api.get(`documentTemplate/${id}`);
	}

	static getDocumentTemplate() {
		return api.get(`documentTemplate`);
	}

	static getDocumentTemplateInfo() {
		return api.get(`documentTemplateInfo`);
	}
	static createDocumentTemplateInfo(data) {
		return api.post(`documentTemplateInfo`, data);
	}
	static updateDocumentTemplateInfo(data) {
		return api.put(`documentTemplateInfo`, data);
	}
	static getDocumentTemplateInfoInTemplateId(templateId) {
		return api.get(`documentTemplateInfo?templateId=${templateId}`);
	}

	static getDocumentTemplateInfoDataInTemplateId(templateId) {
		return api.get(`documentTemplateInfoData?templateId=${templateId}`);
	}
	static createDocumentTemplateInfoData(data) {
		return api.post(`documentTemplateInfoData`, data);
	}
	static updateDocumentTemplateInfoData(data) {
		return api.put(`documentTemplateInfoData`, data);
	}

	static getLibrary() {
		return api.get(`library`);
	}
	static getDocumentTemplate() {
		return api.get(`documentTemplate`);
	}
	static createDocumentTemplate(data) {
		return api.post(`documentTemplate`, data);
	}
	static updateDocumentTemplate(data) {
		return api.put(`documentTemplate`, data);
	}
	static getLectureQuizAtetmpt(quizId, studentId) {
		return api.get(`quizattempt/${quizId}/${studentId}`);
	}
	static getLectureQuiz(quizId, studentId) {
		return api.get(`courseQuiz/lecturer/${quizId}/${studentId}`);
	}

	static sendAltValues(data) {
		return api.post(`fileLibrary/getfilepaths`, data);
	}
}

export default ApiService;
